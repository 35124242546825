import React from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { FaPlay, FaStop } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const VoicePlayButton = ({ isPlaying, onClick, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={isPlaying ? t("Stop Voice") : t("Play Voice")}>
      <IconButton
        aria-label={isPlaying ? t("Stop Voice") : t("Play Voice")}
        icon={isPlaying ? <FaStop /> : <FaPlay />}
        size="sm"
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </Tooltip>
  );
};

export default VoicePlayButton;
