import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading, Text, VStack, useColorModeValue, useToast, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiUrl } from './config/api';

const showErrorToast = (toast, t, message) => {
  toast({
    title: t("Error"),
    description: t(message || "There was an error processing your subscription. Please contact support."),
    status: "error",
    duration: 5000,
    isClosable: true,
  });
};

const SubscriptionSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch(apiUrl('/api/auth/user'), {
          credentials: 'include'
        });
        if (response.ok) {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };

    checkLoginStatus();
  }, []);

  const fetchSubscriptionDetails = useCallback(async (sessionId) => {
    try {
      const response = await fetch(apiUrl(`/api/subscription/session-details?session_id=${sessionId}`), {
        credentials: 'include'
      });
      const responseText = await response.text();
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (error) {
        console.error('Failed to parse response as JSON:', responseText);
        throw new Error('Invalid response from server');
      }
      
      if (!response.ok) {
        throw new Error(data.details || 'Failed to fetch session details');
      }
      
      console.log('Received session details:', data);
      setSubscriptionDetails(data);

      if (data.amount_total && data.payment_status === 'paid') {
        const creditsToAdd = Math.floor(data.amount_total / 100); // Convert cents to dollars
        const creditsResponse = await fetch(apiUrl('/api/user/add-credits'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ 
            credits: creditsToAdd,
            paymentIntentId: data.payment_intent,
            amount: data.amount_total / 100 // Convert cents to dollars
          }),
        });

        const creditsResult = await creditsResponse.json();
        if (!creditsResponse.ok) {
          throw new Error(creditsResult.details || 'Failed to add credits to user account');
        }

        if (creditsResult.success) {
          toast({
            title: t("Credits Added"),
            description: t(`${creditsToAdd} credits have been added to your account and the purchase has been recorded.`),
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          throw new Error('Credits were not added successfully');
        }
      } else if (data.payment_status !== 'paid') {
        throw new Error(`Payment status is ${data.payment_status}`);
      } else {
        throw new Error('No amount found in session details');
      }
    } catch (error) {
      console.error('Error fetching subscription details or adding credits:', error);
      showErrorToast(toast, t, error.message);
    }
  }, [t, toast]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    if (sessionId && isLoggedIn) {
      fetchSubscriptionDetails(sessionId);
    }
  }, [location, isLoggedIn, fetchSubscriptionDetails]);


  useEffect(() => {
    if (subscriptionDetails && isLoggedIn) {
      const redirectTimer = setTimeout(() => {
        navigate('/dashboard');
      }, 5000);

      return () => clearTimeout(redirectTimer);
    }
  }, [subscriptionDetails, navigate, isLoggedIn]);

  return (
    <Box p={8}>
      <VStack spacing={8} align="stretch">
        <Heading>{t("Transaction Successful")}</Heading>
        <Box p={6} borderWidth={1} borderRadius="lg" bg={useColorModeValue('white', 'gray.700')}>
          <VStack spacing={4} align="stretch">
            <Text>{t("Thank you!")}</Text>
            {isLoggedIn ? (
              <Text>{t("You will be redirected to the dashboard in 5 seconds.")}</Text>
            ) : (
              <>
                <Text>{t("Please log in to access your account and see your updated subscription details.")}</Text>
                <Button onClick={() => navigate('/login')} colorScheme="blue">
                  {t("Log In")}
                </Button>
              </>
            )}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default SubscriptionSuccess;
