import React, { useState, useEffect } from 'react';
import { useColorModeValue, Box, Button, FormControl, FormLabel, Input, VStack, Text, Link, useToast, useColorMode, HStack, IconButton, Flex, Menu, MenuButton, MenuList, MenuItem, Collapse, Checkbox, useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SunIcon, MoonIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@chakra-ui/icons';
import { FaAsterisk, FaLanguage } from 'react-icons/fa';
import TermsOfService from './components/TermsOfService';
import { apiUrl } from './config/api';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Logo from './components/Logo';
import { keyframes } from '@emotion/react';
import { isSignUpEnabled } from './config/constants';

const borderAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const animatedButtonStyles = {
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    right: '-2px',
    bottom: '-2px',
    background: 'linear-gradient(45deg, #00BFFF, #1E90FF, #4169E1, #0000FF, #8A2BE2, #9932CC, #8B008B, #00BFFF)',
    backgroundSize: '400%',
    zIndex: -1,
    filter: 'blur(5px)',
    animation: `${borderAnimation} 20s linear infinite`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    borderRadius: 'inherit',
  },
  '&:hover::before': {
    opacity: 1,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'inherit',
    borderRadius: 'inherit',
    zIndex: -1,
  },
};

const SignUp = ({ onSignUp = () => {} }) => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("linear-gradient(to bottom right, #FFFFFF, #F0F8FF)", "black");
  const textColor = useColorModeValue("gray.800", "white");
  const navigate = useNavigate();
  const buttonFontSize = useBreakpointValue({ base: "xs", md: "sm" });

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTOSOpen, setIsTOSOpen] = useState(false);
  const [agreedToTOS, setAgreedToTOS] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validInputs, setValidInputs] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
  });

  useEffect(() => {
    setShowConfirmPassword(password.length > 0);
  }, [password]);

  if (!isSignUpEnabled) {
    return (
      <Box maxWidth="400px" margin="auto" mt={8} p={4} borderWidth={1} borderRadius="lg">
        <VStack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">{t("Sign-ups are currently disabled")}</Text>
          <Text>{t("Please contact the administrator for more information.")}</Text>
          <Button as={Link} to="/login" colorScheme="blue">
            {t("Go to Login")}
          </Button>
        </VStack>
      </Box>
    );
  }

  const isStrongPassword = (password) => {
    const minLength = 12;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSymbols = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
    const notCommonWord = !/^[a-zA-Z]+$/.test(password); // Basic check to ensure it's not just a word

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSymbols &&
      notCommonWord
    );
  };

  const validateInput = (name, value) => {
    switch (name) {
      case 'firstName':
      case 'lastName':
        return value.length > 0;
      case 'email':
        return /\S+@\S+\.\S+/.test(value);
      case 'password':
        return isStrongPassword(value);
      case 'confirmPassword':
        return value === password && isStrongPassword(value);
      default:
        return false;
    }
  };

  const handleInputChange = (name, value) => {
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
        console.warn(`Unhandled input name: ${name}`);
    }
    setValidInputs(prev => ({ ...prev, [name]: validateInput(name, value) }));
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreedToTOS) {
      toast({
        title: t("Please agree to the Terms of Service"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      toast({
        title: t("Please fill in all fields"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (password !== confirmPassword) {
      toast({
        title: t("Passwords do not match"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(apiUrl('/api/auth/register'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, email, password }),
      });
      const data = await response.json();
      if (response.ok) {
        toast({
          title: "Account created.",
          description: "We've created your account for you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onSignUp();
        navigate('/login'); // Redirect to login page after successful signup
      } else {
        throw new Error(data.message || 'An error occurred during sign up');
      }
    } catch (error) {
      toast({
        title: t("An error occurred."),
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Box maxWidth="400px" width="100%">
        <Box 
          borderRadius="0" 
          p={8} 
          boxShadow="lg" 
          bg={bgColor}
          position="relative"
          width="400px"
          height="auto"
          transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
          _hover={{ transform: "scale(1.02)", boxShadow: "xl" }}
          _active={{ transform: "scale(0.98)" }}
        >
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="stretch">
              <HStack justify="space-between">
                <RouterLink to="/">
                  <Flex alignItems="center" cursor="pointer">
                    <Logo size="md" />
                  </Flex>
                </RouterLink>
                <HStack>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label={t("Language")}
                      icon={<FaLanguage />}
                      variant="ghost"
                    />
                    <MenuList>
                      <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
                      <MenuItem onClick={() => handleLanguageChange('fr')}>Français</MenuItem>
                    </MenuList>
                  </Menu>
                  <IconButton
                    icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                    onClick={toggleColorMode}
                    aria-label={`Toggle ${colorMode === 'light' ? 'Dark' : 'Light'} Mode`}
                    variant="ghost"
                  />
                </HStack>
              </HStack>
              <FormControl isRequired>
                <FormLabel color={textColor}>
                  {t("First Name")}
                  {validInputs.firstName ? <CheckIcon color="green.500" ml={2} /> : <FaAsterisk color="red" size="0.5em" style={{display: 'inline', marginLeft: '4px'}} />}
                </FormLabel>
                <Input type="text" value={firstName} onChange={(e) => handleInputChange('firstName', e.target.value)} size="sm" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel color="white">
                  {t("Last Name")}
                  {validInputs.lastName ? <CheckIcon color="green.500" ml={2} /> : <FaAsterisk color="red" size="0.5em" style={{display: 'inline', marginLeft: '4px'}} />}
                </FormLabel>
                <Input type="text" value={lastName} onChange={(e) => handleInputChange('lastName', e.target.value)} size="sm" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel color="white">
                  {t("Email")}
                  {validInputs.email ? <CheckIcon color="green.500" ml={2} /> : <FaAsterisk color="red" size="0.5em" style={{display: 'inline', marginLeft: '4px'}} />}
                </FormLabel>
                <Input type="email" value={email} onChange={(e) => handleInputChange('email', e.target.value)} size="sm" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel color="white">
                  {t("Password")}
                  {validInputs.password ? <CheckIcon color="green.500" ml={2} /> : <FaAsterisk color="red" size="0.5em" style={{display: 'inline', marginLeft: '4px'}} />}
                </FormLabel>
                <Input type="password" value={password} onChange={(e) => handleInputChange('password', e.target.value)} size="sm" />
                {password && !validInputs.password && (
                  <Text color="red.500" fontSize="sm" mt={1}>
                    {t("Password must be at least 12 characters long and include uppercase, lowercase, numbers, and symbols.")}
                  </Text>
                )}
              </FormControl>
              {showConfirmPassword && (
                <FormControl isRequired>
                  <FormLabel color="white">
                    {t("Confirm Password")}
                    {validInputs.confirmPassword ? <CheckIcon color="green.500" ml={2} /> : <FaAsterisk color="red" size="0.5em" style={{display: 'inline', marginLeft: '4px'}} />}
                  </FormLabel>
                  <Input type="password" value={confirmPassword} onChange={(e) => handleInputChange('confirmPassword', e.target.value)} size="sm" />
                </FormControl>
              )}
              <Box>
                <Button
                  rightIcon={isTOSOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={() => setIsTOSOpen(!isTOSOpen)}
                  variant="link"
                  size="sm"
                  color={textColor}
                >
                  {t("Terms of Service")}
                </Button>
                <Collapse in={isTOSOpen} animateOpacity>
                  <TermsOfService />
                </Collapse>
              </Box>
              <Checkbox isChecked={agreedToTOS} onChange={(e) => setAgreedToTOS(e.target.checked)} color="white">
                {t("I agree to the Terms of Service")}
              </Checkbox>
              <Button 
                type="submit" 
                colorScheme="blue" 
                isLoading={isLoading} 
                size="sm"
                isDisabled={!agreedToTOS}
                variant="outline" 
                borderColor="blue.500" 
                color="white" 
                _hover={{ bg: "black" }}
                sx={animatedButtonStyles}
                fontSize={buttonFontSize}
              >
                <Text fontWeight="bold">{t("Sign Up")}</Text>
              </Button>
              <Text color={textColor}>
                {t("Already have an account?")} <Link color="blue.500" onClick={() => navigate('/login')}>{t("Log In")}</Link>
              </Text>
            </VStack>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default SignUp;
