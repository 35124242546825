import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Heading, Text, VStack, HStack, SimpleGrid, Flex, useColorModeValue, Button, useToast, Table, Thead, Tbody, Tr, Th, Td, Spinner, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Select, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import Pagination from './components/Pagination';
import { FaTrash, FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDiscover, FaCreditCard } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { apiUrl } from './config/api';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_test_51Q08xeIIbuCa9DzyiBW21M06ipObofm2YeVNr0HKX0GCMirCtXCxFs32wYTxexvWRKCsVmNEk6yOEp5Rpg7DlgDp002U8ahT56");

const SetupForm = ({ onSuccess, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  const bgColor = useColorModeValue("gray.50", "gray.700");

  useEffect(() => {
    if (!elements) {
      return;
    }

    const fetchUpdates = async () => {
      const { error } = await elements.fetchUpdates();
      if (error) {
        console.error('Error fetching updates:', error);
      }
    };

    fetchUpdates();
    const intervalId = setInterval(fetchUpdates, 5000); // Fetch updates every 5 seconds

    return () => clearInterval(intervalId);
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/subscription`,
        payment_method_data: {
          billing_details: {
            address: {
              line2: '' // Provide an empty string for line2
            }
          }
        }
      },
    });

    if (error) {
      toast({
        title: t("Setup failed"),
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      // The setup was successful, but we don't need to do anything here
      // as the page will be redirected to /subscription
      toast({
        title: t("Setup successful"),
        description: t("Payment method added to your account"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        borderWidth={1}
        borderRadius="md"
        p={4}
        mb={4}
        bg={bgColor}
      >
        <PaymentElement
          options={{
            layout: {
              type: 'tabs',
              defaultCollapsed: false,
              radios: false,
              spacedAccordionItems: false
            },
            fields: {
              billingDetails: 'auto'
            },
            wallets: {
              applePay: 'auto',
              googlePay: 'auto'
            }
          }}
        />
      </Box>
      <Button
        mt={4}
        colorScheme="blue"
        isLoading={isLoading}
        type="submit"
        disabled={!stripe || !elements}
        width="100%"
      >
        {t("Set up payment method")}
      </Button>
    </form>
  );
};

const PaymentMethodDisplay = () => {
  const [paymentMethods] = useState([]);
  const [isLoading] = useState(true);
  const { t } = useTranslation();
  const toast = useToast();
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const getCardIcon = (brand) => {
    switch (brand.toLowerCase()) {
      case 'visa':
        return <FaCcVisa size="2em" />;
      case 'mastercard':
        return <FaCcMastercard size="2em" />;
      case 'amex':
        return <FaCcAmex size="2em" />;
      case 'discover':
        return <FaCcDiscover size="2em" />;
      default:
        return <FaCreditCard size="2em" />;
    }
  };



  const handleDeletePaymentMethod = async (paymentMethodId) => {
    try {
      const response = await fetch(apiUrl('/api/subscription/delete-payment-method'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paymentMethodId }),
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to delete payment method');
      }
      toast({
        title: t("Payment method deleted"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

    } catch (error) {
      console.error('Error deleting payment method:', error);
      toast({
        title: t("Error"),
        description: t("Failed to delete payment method"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  if (paymentMethods.length === 0) {
    return (
      <Box p={4} borderWidth={1} borderRadius="md" borderColor={borderColor} bg={bgColor}>
        <Text>{t("No payment methods configured")}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text fontWeight="bold" fontSize="lg" mb={4}>{t("Payment Methods")}</Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {paymentMethods.map((method) => (
          <Box
            key={method.id}
            p={4}
            borderWidth={1}
            borderRadius="md"
            borderColor={borderColor}
            bg={bgColor}
            boxShadow="sm"
            transition="all 0.2s"
            _hover={{ boxShadow: "md" }}
          >
            <Flex justify="space-between" align="center" mb={2}>
              <Flex align="center">
                {getCardIcon(method.card.brand)}
                <Text fontWeight="bold" ml={2}>{method.card.brand}</Text>
              </Flex>
              <Text>**** {method.card.last4}</Text>
            </Flex>
            <Text mb={2}>{t("Expires")}: {method.card.exp_month}/{method.card.exp_year}</Text>
            <Button
              mt={2}
              size="sm"
              colorScheme="red"
              onClick={() => handleDeletePaymentMethod(method.id)}
              leftIcon={<FaTrash />}
              width="full"
            >
              {t("Delete")}
            </Button>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

const Subscription = () => {
  const { t } = useTranslation();
  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const toast = useToast();
  const cancelRef = useRef();


  const [accountData, setAccountData] = useState({
    credits: 0,
    usedCredits: 0,
  });
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [creditAmount, setCreditAmount] = useState(5);
  const creditOptions = [5, 10, 20, 50, 100];
  const [clientSecret, setClientSecret] = useState(null);
  const [isPaymentMethodSetup, setIsPaymentMethodSetup] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const fetchAccountData = useCallback(async () => {
    try {
      const response = await fetch(apiUrl('/api/subscription/account-data'), {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch account data');
      }
      const data = await response.json();
      setAccountData({
        ...data,
        subscription: data.subscription || null
      });
    } catch (error) {
      console.error('Error fetching account data:', error);
      toast({
        title: t("Error"),
        description: t("Failed to fetch account data"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [t, toast]);

  const fetchPurchaseHistory = useCallback(async () => {
    try {
      const response = await fetch(apiUrl(`/api/subscription/purchase-history?page=${currentPage}&limit=${itemsPerPage}`), {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch purchase history');
      }
      const data = await response.json();
      setPurchaseHistory(data.purchases);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching purchase history:', error);
      toast({
        title: t("Error"),
        description: t("Failed to fetch purchase history"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [t, toast, currentPage, itemsPerPage]);

  const checkPaymentMethod = useCallback(async () => {
    try {
      const response = await fetch(apiUrl('/api/subscription/check-payment-method'), {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to check payment method');
      }
      const data = await response.json();
      setIsPaymentMethodSetup(data.isSetup);
    } catch (error) {
      console.error('Error checking payment method:', error);
    }
  }, []);

  useEffect(() => {
    fetchAccountData();
    fetchPurchaseHistory();
    checkPaymentMethod();

  }, [fetchAccountData, fetchPurchaseHistory, checkPaymentMethod, currentPage, itemsPerPage]);


  const handleAddCredits = async () => {
    try {
      setIsProcessing(true);

      const response = await fetch(apiUrl('/api/subscription/create-checkout-session'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ 
          amount: creditAmount,
        }),
      });
      const data = await response.json();

      if (data.sessionId) {
        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });
        if (error) {
          throw new Error(error.message);
        }
      } else {
        throw new Error(data.error || 'Failed to create checkout session');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      toast({
        title: t("Error"),
        description: error.message || t("Failed to process payment"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const setupPaymentMethod = async () => {
    setIsProcessing(true);
    try {
      const response = await fetch(apiUrl('/api/subscription/create-setup-intent'), {
        method: 'POST',
        credentials: 'include',
      });
      const data = await response.json();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error('Error creating setup intent:', error);
      toast({
        title: t("Error"),
        description: t("Failed to set up payment method"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const elementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',
      },
    },
  };

  const handleCancelSubscription = async () => {
    setIsProcessing(true);
    try {
      const response = await fetch(apiUrl('/api/subscription/cancel'), {
        method: 'POST',
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        toast({
          title: t("Success"),
          description: t("Subscription canceled successfully"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchAccountData();
      } else {
        throw new Error(data.error || 'Failed to cancel subscription');
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      toast({
        title: t("Error"),
        description: error.message || t("Failed to cancel subscription"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
      setIsCancelDialogOpen(false);
    }
  };


  return (
    <Box p={8}>
      <VStack spacing={8} align="stretch">
        <Heading>{t("Account Credits")}</Heading>
        
        <Box p={6} borderWidth={1} borderRadius="lg" bg={cardBg} borderColor={borderColor}>
          <VStack spacing={6} align="stretch">
            <Box 
              borderWidth={1} 
              borderRadius="lg" 
              p={6} 
              bg={useColorModeValue("white", "gray.600")}
              boxShadow="md"
            >
              <VStack spacing={4} align="stretch">
                {clientSecret && (
                  <Box mt={4}>
                    <EmbeddedCheckoutProvider
                      stripe={stripePromise}
                      options={{clientSecret}}
                    >
                      <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                  </Box>
                )}
                <HStack spacing={4} align="stretch" width="100%">
                  <Box
                    borderWidth={1}
                    borderRadius="md"
                    p={4}
                    bg={useColorModeValue("gray.50", "gray.700")}
                    boxShadow="sm"
                    flex={1}
                  >
                    <VStack align="start">
                      <Text fontSize="md" fontWeight="bold">{t("Available Credits")}</Text>
                      <Text fontSize="2xl" fontWeight="bold" color={useColorModeValue("green.600", "green.200")}>
                        ${((accountData.credits || 0) - (accountData.usedCredits || 0)).toFixed(2)}
                      </Text>
                    </VStack>
                  </Box>

                  <Box
                    borderWidth={1}
                    borderRadius="md"
                    p={4}
                    bg={useColorModeValue("gray.50", "gray.700")}
                    flex={1}
                  >
                    <VStack align="start" spacing={4}>
                      <Text fontSize="md" fontWeight="bold">{t("Add Credits")}</Text>
                      <HStack spacing={2} wrap="wrap">
                        {creditOptions.map((amount) => (
                          <Button
                            key={amount}
                            onClick={() => setCreditAmount(amount)}
                            colorScheme={creditAmount === amount ? "blue" : "gray"}
                            variant={creditAmount === amount ? "solid" : "outline"}
                            size="sm"
                          >
                            ${amount}
                          </Button>
                        ))}
                      </HStack>
                      <Button
                        colorScheme="blue"
                        onClick={handleAddCredits}
                        width="full"
                      >
                        {t("Add")} ${creditAmount} {t("Credits")}
                      </Button>
                    </VStack>
                  </Box>
                </HStack>

                <Box mt={4}>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            {t("Payment Methods")}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <PaymentMethodDisplay />

                        {!isPaymentMethodSetup && (
                          <Box mt={4}>
                            <Button colorScheme="green" onClick={setupPaymentMethod}>
                              {t("Set up payment method")}
                            </Button>
                          </Box>
                        )}

                        {clientSecret && (
                          <Box mt={4}>
                            <Elements stripe={stripePromise} options={elementsOptions}>
                              <SetupForm onSuccess={() => {
                                setIsPaymentMethodSetup(true);
                                setClientSecret(null);
                                fetchAccountData();
                              }} />
                            </Elements>
                          </Box>
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>

                {accountData.subscription && (
                  <Box mt={4}>
                    <Button colorScheme="red" onClick={() => setIsCancelDialogOpen(true)}>
                      {t("Cancel Subscription")}
                    </Button>
                  </Box>
                )}
              </VStack>
            </Box>

            <AlertDialog
              isOpen={isCancelDialogOpen}
              leastDestructiveRef={cancelRef}
              onClose={() => setIsCancelDialogOpen(false)}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    {t("Cancel Subscription")}
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    {t("Are you sure you want to cancel your subscription? This action cannot be undone.")}
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={() => setIsCancelDialogOpen(false)}>
                      {t("No, keep my subscription")}
                    </Button>
                    <Button colorScheme="red" onClick={handleCancelSubscription} ml={3} isLoading={isProcessing}>
                      {t("Yes, cancel my subscription")}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>

            <Box mt={6}>
              <Heading size="md" mb={4}>{t("Credit Purchase History")}</Heading>
              <Box 
                borderWidth={1} 
                borderRadius="lg" 
                p={4} 
                bg={useColorModeValue("white", "gray.700")}
                boxShadow="md"
              >
                <Box overflowX="auto">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>{t("Date")}</Th>
                        <Th>{t("Amount")}</Th>
                        <Th>{t("Transaction ID")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {purchaseHistory.map((purchase, index) => (
                        <Tr key={index}>
                          <Td>{new Date(purchase.date).toLocaleString()}</Td>
                          <Td>${Number(purchase.amount).toFixed(2)}</Td>
                          <Td>{purchase.stripe_payment_intent_id}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                <Flex justifyContent="space-between" alignItems="center" mt={4}>
                  <Select
                    value={itemsPerPage}
                    onChange={(e) => {
                      setItemsPerPage(Number(e.target.value));
                      setCurrentPage(1);
                    }}
                    width="auto"
                  >
                    <option value={5}>5 per page</option>
                    <option value={10}>10 per page</option>
                    <option value={50}>50 per page</option>
                    <option value={100}>100 per page</option>
                  </Select>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </Flex>
              </Box>
            </Box>
          </VStack>
        </Box>

        <Text fontSize="sm" color="gray.500">
          {t("For any questions about your account credits or billing, please contact our support team.")}
        </Text>
      </VStack>
    </Box>
  );
};

export default Subscription;
