import React from 'react';
import { Box, Heading, Text, VStack, Icon, UnorderedList, ListItem, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash, FaPhone, FaComments, FaSave, FaCopy } from 'react-icons/fa';

const Help = () => {
  const { t, i18n } = useTranslation();

  const helpItems = {
    en: [
      {
        title: "Language Settings",
        description: "You can change the application language in the Settings page. Currently, we support English and French."
      },
      {
        title: "Navigation",
        description: "Use the left sidebar to navigate between different sections of the application. Main sections can be expanded to show sub-items."
      },
      {
        title: "Color Mode",
        description: "You can toggle between light and dark mode using the color mode switch in the bottom left corner of the sidebar."
      },
      {
        title: "Agent Profiles",
        description: "Agent profiles represent the personalities of AI agents, configuring their behavior during phone interactions. Here's how to use them:",
        subItems: [
          { title: "Creating and Editing Profiles", description: "To create a new profile, click the '+' button on the agent profiles page. To edit an existing profile, click the edit icon ", icon: FaEdit, iconColor: "yellow.500", additionalDescription: " next to it. Fill in or modify information such as name, gender, voice, type, and subtype." },
          { title: "Deleting a Profile", description: "Use the delete icon ", icon: FaTrash, iconColor: "red.500", additionalDescription: " to remove a profile you no longer need." },
          { title: "Voice Selection", description: "When creating or editing a profile, you can choose a voice and listen to a sample by clicking the play button next to the voice selection dropdown." },
          { title: "Profile Types and Subtypes", description: "Currently, we support VoIP as the main type. For subtypes, choose between 'BlackBox' (predefined roles like Receptionist, Support, or General) or 'Custom' (where you can enter a custom prompt to define the agent's behavior and personality)." },
          { title: "Gender and Voice", description: "Selecting the gender filters the available voices. Make sure to select a voice that matches the chosen gender." },
          { title: "Using Profiles", description: "Once created, you can use these profiles to:" },
          { title: "Call an Agent", description: "Click the phone icon ", icon: FaPhone, iconColor: "green.500", additionalDescription: " next to a profile to initiate a call with that agent." },
          { title: "Start a Text Session", description: "Click the chat icon ", icon: FaComments, iconColor: "blue.500", additionalDescription: " next to a profile to start a text conversation with that agent." },
          { title: "Managing Profiles", description: "Use the search bar at the top of the agent profiles page to quickly find specific profiles. Click on column headers in the profiles table to sort the list by that attribute." },
          { title: "Converting to Template", description: "Click the save icon ", icon: FaSave, iconColor: "purple.500", additionalDescription: " to convert a profile into a private template for future use." }
        ]
      },
      {
        title: "Employee Handbook",
        description: "The Employee Handbook feature allows you to upload and use a PDF document containing specific instructions or guidelines for your AI agent. Here's how to use this feature:",
        subItems: [
          { title: "Enabling the Employee Handbook", description: "In the agent profile edit modal, navigate to the 'Memories' tab. You'll find a switch to enable the Employee Handbook feature." },
          { title: "Uploading the Handbook", description: "Once enabled, you can upload a PDF file containing your employee handbook. This document should include any specific instructions, guidelines, or information you want your AI agent to reference." },
          { title: "File Requirements", description: "The uploaded file must be in PDF format. There may be size limitations, so ensure your file is not excessively large." },
          { title: "Using the Handbook", description: "Once uploaded, the AI agent will use the information in the handbook to inform its responses and behavior during interactions." },
          { title: "Updating the Handbook", description: "You can update the employee handbook at any time by uploading a new PDF file. This will replace the existing handbook for that agent profile." },
          { title: "Privacy and Security", description: "The uploaded handbook is securely stored and is only accessible to the AI agent associated with that specific profile." },
          { title: "Best Practices", description: "For optimal results, ensure your handbook is clear, concise, and well-organized. Include key information that you want the AI to prioritize in its interactions." }
        ]
      },
    ],
    fr: [
      {
        title: "Paramètres de langue",
        description: "Vous pouvez changer la langue de l'application dans la page Paramètres. Actuellement, nous prenons en charge l'anglais et le français."
      },
      {
        title: "Navigation",
        description: "Utilisez la barre latérale gauche pour naviguer entre les différentes sections de l'application. Les sections principales peuvent être développées pour afficher les sous-éléments."
      },
      {
        title: "Mode de couleur",
        description: "Vous pouvez basculer entre le mode clair et le mode sombre en utilisant le commutateur de mode de couleur dans le coin inférieur gauche de la barre latérale."
      },
      {
        title: "Profils d'agents",
        description: "Les profils d'agents représentent les personnalités des agents IA, configurant leur comportement lors des interactions téléphoniques. Voici comment les utiliser :",
        subItems: [
          { title: "Création et modification de profils", description: "Pour créer un nouveau profil, cliquez sur le bouton '+' sur la page des profils d'agents. Pour modifier un profil existant, cliquez sur l'icône d'édition ", icon: FaEdit, iconColor: "yellow.500", additionalDescription: " à côté. Remplissez ou modifiez les informations telles que le nom, le sexe, la voix, le type et le sous-type." },
          { title: "Suppression d'un profil", description: "Utilisez l'icône de suppression ", icon: FaTrash, iconColor: "red.500", additionalDescription: " pour supprimer un profil dont vous n'avez plus besoin." },
          { title: "Sélection de la voix", description: "Lors de la création ou de la modification d'un profil, vous pouvez choisir une voix et écouter un échantillon en cliquant sur le bouton de lecture à côté du menu déroulant de sélection de la voix." },
          { title: "Types et sous-types de profils", description: "Actuellement, nous prenons en charge VoIP comme type principal. Pour les sous-types, choisissez entre 'BlackBox' (rôles prédéfinis comme Réceptionniste, Support ou Général) ou 'Personnalisé' (où vous pouvez entrer une invite personnalisée pour définir le comportement et la personnalité de l'agent)." },
          { title: "Sexe et voix", description: "La sélection du sexe filtre les voix disponibles. Assurez-vous de sélectionner une voix qui correspond au sexe choisi." },
          { title: "Utilisation des profils", description: "Une fois créés, vous pouvez utiliser ces profils pour :" },
          { title: "Appeler un agent", description: "Cliquez sur l'icône du téléphone ", icon: FaPhone, iconColor: "green.500", additionalDescription: " à côté d'un profil pour initier un appel avec cet agent." },
          { title: "Démarrer une session de texte", description: "Cliquez sur l'icône de chat ", icon: FaComments, iconColor: "blue.500", additionalDescription: " à côté d'un profil pour démarrer une conversation textuelle avec cet agent." },
          { title: "Gestion des profils", description: "Utilisez la barre de recherche en haut de la page des profils d'agents pour trouver rapidement des profils spécifiques. Cliquez sur les en-têtes de colonne dans le tableau des profils pour trier la liste par cet attribut." },
          { title: "Conversion en modèle", description: "Cliquez sur l'icône de sauvegarde ", icon: FaSave, iconColor: "purple.500", additionalDescription: " pour convertir un profil en un modèle privé pour une utilisation future." }
        ]
      },
      {
        title: "Modèles d'agents",
        description: "Les modèles d'agents sont des profils préconfigurés qui peuvent être utilisés pour créer rapidement de nouveaux profils d'agents. Voici comment les utiliser :",
        subItems: [
          { title: "Création et modification de modèles", description: "Pour créer un nouveau modèle, cliquez sur le bouton '+' sur la page des modèles d'agents. Pour modifier un modèle existant, cliquez sur l'icône d'édition ", icon: FaEdit, iconColor: "yellow.500", additionalDescription: " à côté." },
          { title: "Suppression d'un modèle", description: "Utilisez l'icône de suppression ", icon: FaTrash, iconColor: "red.500", additionalDescription: " pour supprimer un modèle dont vous n'avez plus besoin." },
          { title: "Confidentialité des modèles", description: "Tous les modèles sont privés par défaut, ce qui signifie qu'ils ne sont visibles que par vous." },
          { title: "Utilisation des modèles", description: "Les modèles peuvent être utilisés pour créer rapidement de nouveaux profils d'agents. Cliquez sur l'icône de copie ", icon: FaCopy, iconColor: "green.500", additionalDescription: " à côté d'un modèle pour créer un nouveau profil basé sur ce modèle." },
          { title: "Gestion des modèles", description: "Utilisez la barre de recherche en haut de la page des modèles d'agents pour trouver rapidement des modèles spécifiques. Cliquez sur les en-têtes de colonne dans le tableau des modèles pour trier la liste par cet attribut." }
        ]
      },
    ]
  };

  const currentLanguage = i18n.language.startsWith('fr') ? 'fr' : 'en';
  const currentHelpItems = helpItems[currentLanguage];

  const TableOfContents = ({ items }) => (
    <Box mb={8} p={4} borderWidth={1} borderRadius="md" boxShadow="md">
      <Heading as="h2" size="md" mb={4}>{t("Table of Contents")}</Heading>
      <UnorderedList spacing={2}>
        {items.map((item, index) => (
          <ListItem key={index}>
            <Link href={`/help#${item.title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`}>{item.title}</Link>
            {item.subItems && (
              <UnorderedList ml={4} mt={2}>
                {item.subItems.map((subItem, subIndex) => (
                  <ListItem key={subIndex}>
                    <Link href={`/help#${subItem.title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`}>{subItem.title}</Link>
                  </ListItem>
                ))}
              </UnorderedList>
            )}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );

  return (
    <Box p={8}>
      <Heading mb={6}>{t("Help")}</Heading>
      <Text mb={4}>{t("Welcome to the MyBot.Agency help page. Here you can find information on how to use the various features of the application.")}</Text>
      <TableOfContents items={currentHelpItems} />
      <VStack spacing={8} align="stretch">
        {currentHelpItems.map((item, index) => (
          <Box key={index} borderWidth={1} borderRadius="lg" p={6} boxShadow="md">
            <Heading as="h2" size="lg" mb={4} id={item.title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>{item.title}</Heading>
            <Text mb={4}>{item.description}</Text>
            {item.subItems && (
              <VStack align="stretch" spacing={4} pl={4}>
                {item.subItems.map((subItem, subIndex) => (
                  <Box key={subIndex} borderWidth={1} borderRadius="md" p={4} bg="gray.50" _dark={{ bg: "gray.700" }}>
                    <Heading as="h3" size="md" mb={2} id={subItem.title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>{subItem.title}</Heading>
                    <Text>
                      {subItem.description}
                      {subItem.icon && (
                        <Icon
                          as={subItem.icon}
                          color={subItem.iconColor}
                          mx={1}
                          boxSize={4}
                        />
                      )}
                      {subItem.additionalDescription}
                    </Text>
                  </Box>
                ))}
              </VStack>
            )}
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default Help;
