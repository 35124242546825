import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Dashboard = () => {
  return (
    <Box>
      <Text>Dashboard</Text>
    </Box>
  );
};

export default Dashboard;
