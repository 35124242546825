import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <Box
      p="40px"
      color="white"
      mt="4"
      bg="gray.700"
      rounded="md"
      shadow="md"
      maxHeight="300px"
      overflowY="auto"
    >
      <Text fontSize="sm" whiteSpace="pre-wrap">
        {t(`Terms of Service (ToS) for voxAI Call Services
Last updated: 01-08-2024

Please read these Terms of Service ("Terms", "ToS") carefully before using our AI-enabled call services ("Service"). By using the Service, you ("User") agree to be bound by these Terms and accept full responsibility for all actions conducted through our platform, including but not limited to making AI-generated or assisted calls. If you do not agree to these Terms, you must not use the Service.

1. Acceptance of Responsibility
1.1. By using the Service, the User acknowledges and accepts full responsibility for all AI-generated or assisted communications made through the Service, including but not limited to calls, messages, or any other forms of communication.

1.2. The User agrees not to use the Service for any illegal, harmful, or malicious purposes and acknowledges that all actions carried out using the Service are their sole responsibility.

1.3. The User agrees to comply with all applicable local, state, national, and international laws and regulations governing the use of AI in telecommunications, including those related to privacy, data protection, consumer protection, and telecommunications laws.

2. Compliance with U.S. Law
2.1. The User must comply with U.S. federal laws, including the Telephone Consumer Protection Act (TCPA), Telemarketing Sales Rule (TSR), and any applicable regulations enforced by the Federal Communications Commission (FCC).

2.2. The User agrees to avoid making AI-generated or assisted calls to U.S. recipients who are on the National Do Not Call Registry, and the User shall be solely responsible for ensuring compliance with such laws.

2.3. The User agrees that any AI-generated or assisted robocalls or auto-dialed calls made without proper consent may be subject to fines and penalties under U.S. law. It is the User's responsibility to ensure that prior express written consent is obtained before engaging in telemarketing or automated calling practices.

3. Compliance with Canadian Law
3.1. The User must comply with Canada's Anti-Spam Legislation (CASL) and the Canadian Radio-television and Telecommunications Commission (CRTC) regulations governing telecommunications.

3.2. The User agrees to ensure that AI-generated or assisted calls made to Canadian residents comply with Canadian privacy laws, including the Personal Information Protection and Electronic Documents Act (PIPEDA).

3.3. The User acknowledges that unsolicited telemarketing calls to Canadian residents without explicit consent may result in penalties under Canadian law, and the User shall bear full responsibility for compliance.

4. Compliance with European Law
4.1. The User must comply with the General Data Protection Regulation (GDPR) and applicable national laws governing the use of AI and telecommunications within the European Economic Area (EEA).

4.2. The User agrees to obtain explicit consent from individuals before initiating AI-generated or assisted calls in the EEA, and to respect the right to be forgotten, as well as other data subject rights as defined by GDPR.

4.3. The User acknowledges that breaches of GDPR, such as failing to obtain appropriate consent for marketing calls, may result in significant fines, and the User shall be solely liable for such breaches.

5. Data and Privacy
5.1. The User agrees that any data collected, processed, or transmitted through the Service shall be handled in compliance with the privacy laws of the relevant jurisdictions, including but not limited to the California Consumer Privacy Act (CCPA) in the U.S., PIPEDA in Canada, and GDPR in the EEA.

5.2. The User is responsible for ensuring that all personal data processed via the Service, including audio recordings or transcripts, is obtained lawfully, and that individuals' privacy rights are respected.

6. Indemnification
6.1. The User agrees to indemnify and hold harmless the Service provider from and against any claims, damages, liabilities, costs, or expenses (including legal fees) arising from the User's use of the Service in violation of any applicable law or regulation, including, but not limited to, any unauthorized or illegal use of AI in telecommunications.

7. Limitation of Liability
7.1. The Service provider shall not be held liable for any damages arising from the User's misuse or non-compliance with applicable laws or these Terms in relation to AI-generated or assisted communications.

7.2. In no event shall the Service provider be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the User's use of the Service, even if advised of the possibility of such damages.

8. Termination
8.1. The Service provider reserves the right to suspend or terminate access to the Service at any time, without prior notice, for any breach of these Terms or any applicable laws or regulations.

9. Governing Law
9.1. These Terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law principles.

9.2. Any disputes arising from or relating to these Terms shall be resolved exclusively in the courts of [Insert Jurisdiction].

10. Amendments
10.1. The Service provider reserves the right to amend these Terms at any time. Users will be notified of any material changes and continued use of the Service following such notice will constitute acceptance of the updated Terms.

By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.`)}
      </Text>
    </Box>
  );
};

export default TermsOfService;
