import React from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Settings = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Box p={8}>
      <Heading mb={6}>{t("Settings")}</Heading>
      <VStack spacing={4} align="stretch">
        {/* Add settings options here */}
      </VStack>
    </Box>
  );
};

export default Settings;
