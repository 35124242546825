import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useToast,
  Input,
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaSearch, FaSortUp, FaSortDown } from 'react-icons/fa';
import VoicePlayButton from './components/VoicePlayButton';
import { apiUrl } from './config/api';

const VoiceTable = ({ voices, playingVoice, handlePlay, handleSort, sortBy, sortOrder, t }) => (
  <Table variant="simple">
    <Thead>
      <Tr>
        {['name', 'language', 'accent', 'gender', 'age'].map((key) => (
          <Th key={key} onClick={() => handleSort(key)} cursor="pointer">
            <Flex alignItems="center">
              {t(key.charAt(0).toUpperCase() + key.slice(1))}
              {sortBy === key && (
                <Box ml={2}>
                  {sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />}
                </Box>
              )}
            </Flex>
          </Th>
        ))}
        <Th>{t("Actions")}</Th>
      </Tr>
    </Thead>
    <Tbody>
      {voices.map((voice) => (
        <Tr key={voice.id}>
          <Td>{voice.name}</Td>
          <Td>{voice.language}</Td>
          <Td>{voice.accent}</Td>
          <Td>{voice.gender}</Td>
          <Td>{voice.age}</Td>
          <Td>
            <VoicePlayButton
              isPlaying={playingVoice === voice.id}
              onClick={() => handlePlay(voice)}
              isDisabled={!voice.sample_url}
            />
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

const VoiceProvider = ({ providerName, t }) => {
  const [voices, setVoices] = useState([]);
  const [sortedVoices, setSortedVoices] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const toast = useToast();
  const [playingVoice, setPlayingVoice] = useState(null);
  const audioRef = useRef(new Audio());

  const fetchVoices = useCallback(async () => {
    try {
      const response = await fetch(apiUrl(`/api/voices/provider/${providerName}`));
      if (!response.ok) {
        throw new Error(`Failed to fetch ${providerName} voices`);
      }
      const data = await response.json();
      setVoices(data);
      setSortedVoices(sortAndFilterVoices(data, sortBy, sortOrder, searchTerm));
    } catch (error) {
      console.error(`Error fetching ${providerName} voices:`, error);
      toast({
        title: `Error fetching ${providerName} voices`,
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast, sortBy, sortOrder, searchTerm, providerName]);

  useEffect(() => {
    fetchVoices();
  }, [fetchVoices]);

  const handlePlay = (voice) => {
    if (voice.sample_url) {
      if (playingVoice === voice.id) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setPlayingVoice(null);
      } else {
        if (playingVoice !== null) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        audioRef.current.src = voice.sample_url;
        audioRef.current.play();
        setPlayingVoice(voice.id);
      }
    } else {
      console.log('No sample URL available');
    }
  };

  useEffect(() => {
    const handleAudioEnded = () => {
      setPlayingVoice(null);
    };

    const currentAudio = audioRef.current;
    currentAudio.addEventListener('ended', handleAudioEnded);

    return () => {
      currentAudio.removeEventListener('ended', handleAudioEnded);
    };
  }, []);

  const sortAndFilterVoices = (voicesToSort, sortKey, order, filter) => {
    return [...voicesToSort]
      .filter(voice => 
        voice.name.toLowerCase().includes(filter.toLowerCase()) ||
        voice.language.toLowerCase().includes(filter.toLowerCase()) ||
        voice.accent.toLowerCase().includes(filter.toLowerCase()) ||
        voice.gender.toLowerCase().includes(filter.toLowerCase()) ||
        (voice.age && voice.age.toLowerCase().includes(filter.toLowerCase()))
      )
      .sort((a, b) => {
        if (a[sortKey] < b[sortKey]) return order === 'asc' ? -1 : 1;
        if (a[sortKey] > b[sortKey]) return order === 'asc' ? 1 : -1;
        return 0;
      });
  };

  const handleSort = (key) => {
    const newOrder = sortBy === key && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortBy(key);
    setSortOrder(newOrder);
    setSortedVoices(sortAndFilterVoices(voices, key, newOrder, searchTerm));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSortedVoices(sortAndFilterVoices(voices, sortBy, sortOrder, event.target.value));
  };

  return (
    <Box>
      <Flex mb={4}>
        <Input
          placeholder={t("Search voices...")}
          value={searchTerm}
          onChange={handleSearch}
          mr={2}
        />
        <IconButton
          aria-label="Search"
          icon={<FaSearch />}
          onClick={() => {}}
        />
      </Flex>
      <VoiceTable 
        voices={sortedVoices}
        playingVoice={playingVoice}
        handlePlay={handlePlay}
        handleSort={handleSort}
        sortBy={sortBy}
        sortOrder={sortOrder}
        t={t}
      />
    </Box>
  );
};

const VoiceManagement = () => {
  const { t } = useTranslation();

  return (
    <Box p={8}>
      <Heading as="h1" size="xl" mb={6}>{t("Available Voices")}</Heading>
      <Tabs>
        <TabList>
          <Tab>{t("PlayHT")}</Tab>
          <Tab>{t("Deepgram")}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VoiceProvider providerName="PlayHT" t={t} />
          </TabPanel>
          <TabPanel>
            <VoiceProvider providerName="Deepgram" t={t} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default VoiceManagement;
