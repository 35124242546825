import React, { useState, useEffect, useCallback } from 'react';
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td, Input, Alert, AlertIcon, IconButton, Tooltip } from '@chakra-ui/react';
import { FaPlayCircle, FaFileAlt, FaListAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { apiUrl } from './config/api';

const formatDate = (dateString) => {
  const options = { 
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit', 
    hour12: true 
  };
  return new Date(dateString).toLocaleString('en-US', options);
};

// Configure axios to use the correct API port
axios.defaults.baseURL = apiUrl('/');

const CallHistory = () => {
  const { t } = useTranslation();
  const [calls, setCalls] = useState([]);
  const [sortedCalls, setSortedCalls] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKeys, setSortKeys] = useState(['calldate']);
  const [sortOrders, setSortOrders] = useState(['desc']);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCalls = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get('/api/calls', {
        withCredentials: true,
        params: { 
          search: searchTerm,
          sortBy: sortKeys[0],
          sortOrder: sortOrders[0]
        }
      });
      setCalls(response.data);
      if (response.data.length === 0) {
        setError('No call history found.');
      }
    } catch (error) {
      console.error('Error fetching calls:', error);
      setError('Failed to fetch call history. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [searchTerm, sortKeys, sortOrders]);

  useEffect(() => {
    fetchCalls();
  }, [fetchCalls]);

  const sortCalls = useCallback((callsToSort) => {
    return callsToSort.sort((a, b) => {
      for (let i = 0; i < sortKeys.length; i++) {
        const key = sortKeys[i];
        const order = sortOrders[i];
        if (a[key] < b[key]) return order === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [sortKeys, sortOrders]);

  useEffect(() => {
    const sorted = sortCalls(calls);
    setSortedCalls(sorted);
  }, [calls, sortCalls]);

  const handleSort = (key) => {
    const index = sortKeys.indexOf(key);
    let newSortKeys = [...sortKeys];
    let newSortOrders = [...sortOrders];
    if (index === -1) {
      newSortKeys = [key, ...sortKeys.filter(k => k !== key)];
      newSortOrders = ['asc', ...newSortOrders.slice(0, -1)];
    } else {
      newSortOrders[index] = sortOrders[index] === 'asc' ? 'desc' : 'asc';
    }
    setSortKeys(newSortKeys);
    setSortOrders(newSortOrders);
    fetchCalls(); // Trigger a new fetch with updated sort parameters
  };

  const handleRecording = (call) => {
    // Implement recording functionality
    console.log('Recording for call:', call);
    // You might want to open a modal or navigate to a new page to play the recording
  };

  const handleViewTranscript = (call) => {
    // Implement view transcript functionality
    console.log('View transcript for call:', call);
    // You might want to open a modal or navigate to a new page to show the transcript
  };

  const handleViewSummary = (call) => {
    // Implement view summary functionality
    console.log('View summary for call:', call);
    // You might want to open a modal or navigate to a new page to show the summary
  };

  return (
    <Box p={5}>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        {t("Call History")}
      </Text>
      <Input
        placeholder={t("Search by source or destination")}
        mb={4}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          if (e.target.value === '') {
            fetchCalls();
          }
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            fetchCalls();
          }
        }}
      />
      {loading ? (
        <Text>{t("Loading...")}</Text>
      ) : error ? (
        <Alert status="warning">
          <AlertIcon />
          {t(error)}
        </Alert>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th onClick={() => handleSort('calldate')}>{t("Date")}</Th>
              <Th onClick={() => handleSort('clid')}>{t("Caller ID")}</Th>
              <Th onClick={() => handleSort('endpoint_destination')}>{t("Destination")}</Th>
              <Th onClick={() => handleSort('duration')}>{t("Duration")}</Th>
              <Th onClick={() => handleSort('disposition')}>{t("Disposition")}</Th>
              <Th>{t("Actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedCalls.map((call, index) => (
              <Tr key={index}>
                <Td maxWidth="180px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {formatDate(call.calldate)}
                </Td>
                <Td>{call.clid}</Td>
                <Td>{call.endpoint_destination || call.endpoint_id}</Td>
                <Td>{call.duration} {t("seconds")}</Td>
                <Td>{call.disposition}</Td>
                <Td>
                  <Tooltip label={t("Recording")}>
                    <IconButton
                      icon={<FaPlayCircle />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleRecording(call)}
                      aria-label={t("Recording")}
                      mr={2}
                      color="green.500"
                    />
                  </Tooltip>
                  <Tooltip label={t("View Transcript")}>
                    <IconButton
                      icon={<FaFileAlt />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleViewTranscript(call)}
                      aria-label={t("View Transcript")}
                      mr={2}
                      color="blue.500"
                    />
                  </Tooltip>
                  <Tooltip label={t("View Summary")}>
                    <IconButton
                      icon={<FaListAlt />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleViewSummary(call)}
                      aria-label={t("View Summary")}
                      color="purple.500"
                    />
                  </Tooltip>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default CallHistory;
