import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  VStack,
  Text,
  useToast,
  Flex,
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { format } from 'date-fns';

const ChatModal = ({ isOpen, onClose, profile }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const cancelRef = useRef();
  const toast = useToast();

  const handleClose = () => {
    setIsConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
    onClose();
  };

  const handleCancelClose = () => {
    setIsConfirmOpen(false);
  };

  const initializeChat = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/completions', {
        prompt: `${profile.prompt}. You are anwsering a phone call.`,
        max_tokens: 150,
        temperature: 0.7,
      });

      const aiResponse = response.data.choices[0].text.trim();
      setMessages([{ text: aiResponse, sender: 'ai', timestamp: new Date().toISOString() }]);
    } catch (error) {
      console.error('Error initializing chat:', error);
      toast({
        title: 'Error',
        description: 'Failed to initialize chat. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [profile, toast]);

  useEffect(() => {
    scrollToBottom();
    if (isOpen) {
      initializeChat();
    }
  }, [isOpen, profile, initializeChat]);


  useEffect(() => {
    if (isOpen) {
      initializeChat();
    }
  }, [isOpen, profile, initializeChat]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    const timestamp = new Date().toISOString();
    const newMessage = { text: inputMessage, sender: 'user', timestamp };
    setMessages([...messages, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      const response = await axios.post('/api/completions', {
        prompt: `${profile.name}: ${inputMessage}`,
        max_tokens: 150,
        temperature: 0.7,
      });

      const aiResponse = response.data.choices[0].text.trim();
      setMessages(prevMessages => [...prevMessages, { text: aiResponse, sender: 'ai', timestamp: new Date().toISOString() }]);
    } catch (error) {
      console.error('Error in chat completion:', error);
      toast({
        title: 'Error',
        description: 'Failed to get AI response. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size={["xl", "3xl"]} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Chat with <Text as="span" color="green.300" fontWeight="bold">{profile.name}</Text>
          </ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody bg="black" p={4}>
          <VStack spacing={4} align="stretch" height="440px" overflowY="auto" bg="black">
            {messages.map((message, index) => (
              <Flex
                key={index}
                width="100%"
                justifyContent={message.sender === 'user' ? 'flex-end' : 'flex-start'}
              >
                <Box
                  maxWidth="80%"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  borderColor={message.sender === 'user' ? 'blue.200' : 'green.300'}
                  p={3}
                  position="relative"
                  role="group"
                >
                  {message.sender === 'ai' && (
                    <>
                      <Text
                        position="absolute"
                        top="50%"
                        left="-20px"
                        bg="black"
                        px={2}
                        py={1}
                        fontSize="xs"
                        fontWeight="bold"
                        borderWidth="1px"
                        borderRadius="md"
                        zIndex={1}
                        transform="translateY(-50%) rotate(-90deg)"
                        transformOrigin="center"
                        color="purple.200"
                        borderColor="green.300"
                      >
                        Agent
                      </Text>
                    </>
                  )}
                  <Box position="relative">
                    <Flex alignItems="center" mb={1}>
                      <Box
                        bg={message.sender === 'user' ? 'blue.700' : 'green.700'}
                        borderRadius="md"
                        px={2}
                        py={1}
                        mr={2}
                        ml={message.sender === 'ai' ? 4 : 0}  // Increased left margin for AI messages
                      >
                        <Text
                          color={message.sender === 'user' ? 'blue.200' : 'green.200'}
                          fontSize="xs"
                          fontWeight="bold"
                        >
                          {message.timestamp && !isNaN(new Date(message.timestamp).getTime())
                            ? ' ' + format(new Date(message.timestamp), 'HH:mm:ss')
                            : ' Time not available'}
                        </Text>
                      </Box>
                      <Text
                        color={message.sender === 'user' ? 'blue.200' : 'green.300'}
                        whiteSpace="pre-wrap"
                        wordBreak="break-word"
                      >
                        {message.text}
                      </Text>
                    </Flex>
                    {message.sender === 'ai' && (
                      <Box
                        position="absolute"
                        bottom="-20px"
                        right="-15px"
                        zIndex={1}
                      >
                        <Tooltip label="Report this response" placement="top">
                          <IconButton
                            icon={<WarningTwoIcon />}
                            aria-label="Report response"
                            size="sm"
                            variant="ghost"
                            colorScheme="red"
                            color="gray.600"
                            _hover={{ color: "red.500" }}
                            onClick={() => {
                              toast({
                                title: "Response Reported",
                                description: "Thank you for your feedback. We will review this response.",
                                status: "info",
                                duration: 3000,
                                isClosable: true,
                              });
                            }}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Flex>
            ))}
            <div ref={messagesEndRef} />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Flex flex={1}>
              <Textarea
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type your message..."
                mr={2}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                bg="black"
                color="green.300"
                _placeholder={{ color: "green.400" }}
                borderColor="green.500"
                maxHeight="200px"
                minHeight="80px"
                resize="vertical"
                rows={3}
              />
              <Button 
                onClick={handleSendMessage} 
                isLoading={isLoading}
                bg="black"
                color="green.300"
                borderColor="green.500"
                borderWidth="1px"
                _hover={{
                  bg: "green.700",
                }}
                _active={{
                  bg: "green.600",
                }}
              >
                Send
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <AlertDialog
      isOpen={isConfirmOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleCancelClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Close Chat
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to close this chat? Any unsaved progress will be lost.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleCancelClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleConfirmClose} ml={3}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
    </>
  );
};

export default ChatModal;
