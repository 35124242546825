import React from 'react';
import { Box, Heading, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { motion } from 'framer-motion';

const MotionBox = motion.create(Box);

const Logo = ({ size }) => {
  const fontSize = useBreakpointValue({ base: "2rem", sm: "2rem", md: "2rem", lg: "2.5rem" });
  
  const letterVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const voxColor = useColorModeValue("black", "white");
  
  const colorVariants = {
    initial: { color: voxColor },
    blue: { color: "transparent", backgroundClip: "text", backgroundImage: "linear-gradient(to bottom, #00BFFF, #1E90FF, #0000FF)" },
  };

  return (
    <>
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink&display=swap');
        `}
      />
      <Heading
        as={motion.h1}
        size={size}
        fontWeight="bold"
        fontFamily="'Reem Kufi Ink', sans-serif"
        textShadow="2px 2px 4px rgba(0,0,0,0.5)"
        fontSize={fontSize}
        textAlign="center"
        display="flex"
        justifyContent="center"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        {['v', 'o', 'x', 'a', 'i'].map((letter, index) => (
          <MotionBox
            key={index}
            as="span"
            variants={letterVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: index * 0.1 }}
            whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}
          >
            <MotionBox
              as="span"
              variants={index >= 3 ? colorVariants : {}}
              initial="initial"
              animate={index >= 3 ? "blue" : "initial"}
              transition={{ delay: index >= 3 ? 1.5 + (index - 3) * 0.3 : 0.5 + index * 0.1 }}
              color={index < 3 ? voxColor : undefined}
            >
              {letter}
            </MotionBox>
          </MotionBox>
        ))}
      </Heading>
    </>
  );
};

export default Logo;
