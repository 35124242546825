import React, { useEffect, useState, useCallback } from 'react';
import { Box, Flex, VStack, Text, Button, IconButton, useColorModeValue, Tooltip, Divider, useToast, Menu, MenuButton, MenuList, MenuItem, FormControl, FormLabel, Input, useColorMode, useBreakpointValue, ChakraProvider, theme } from '@chakra-ui/react';
import { FaCog, FaUser, FaQuestionCircle, FaUserFriends, FaPhoneAlt, FaIdCard, FaNetworkWired, FaHeadset, FaPhone, FaHistory, FaPlayCircle, FaMicrophone, FaChartBar, FaSignOutAlt, FaEnvelope, FaMoon, FaSun, FaLanguage, FaMoneyBillWave, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import Subscription from './Subscription';
import Settings from './Settings';
import Help from './Help';
import SignUp from './SignUp';
import Login from './Login';
import AgentProfiles from './AgentProfiles';
import VoiceManagement from './VoiceManagement';
import Contact from './pages/Contact';
import Dashboard from './Dashboard';
import CallHistory from './CallHistory';
import Endpoints from './Endpoints';
import IVRs from './IVRs';
import SubscriptionSuccess from './SubscriptionSuccess';
import ChangePlan from './ChangePlan';
import { apiUrl } from './config/api';
import Logo from './components/Logo';
import { isSignUpEnabled } from './config/constants';

// Polyfill for 'global' in browser environment
if (typeof global === 'undefined') {
  window.global = window;
}

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_51Q08xeIIbuCa9DzyiBW21M06ipObofm2YeVNr0HKX0GCMirCtXCxFs32wYTxexvWRKCsVmNEk6yOEp5Rpg7DlgDp002U8ahT56");


const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast({
        title: t("Passwords do not match"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const response = await fetch(apiUrl('/api/auth/change-password'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      });
      if (!response.ok) {
        throw new Error('Failed to change password');
      }
      toast({
        title: t("Password changed successfully"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      toast({
        title: t("Error changing password"),
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl isRequired>
          <FormLabel>{t("Old Password")}</FormLabel>
          <Input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{t("New Password")}</FormLabel>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{t("Confirm New Password")}</FormLabel>
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FormControl>
        <Button type="submit" colorScheme="blue">
          {t("Change Password")}
        </Button>
      </VStack>
    </form>
  );
};


const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label={t("Language")}
        icon={<FaLanguage />}
        variant="ghost"
      />
      <MenuList>
        <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('fr')}>Français</MenuItem>
      </MenuList>
    </Menu>
  );
};

const LeftPanel = ({ handleLogout }) => {
  const { t } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const [activeMenus, setActiveMenus] = useState(() => {
    const savedActiveMenus = localStorage.getItem('activeMenus');
    return savedActiveMenus !== null ? JSON.parse(savedActiveMenus) : [];
  });
  const shouldMinimize = useBreakpointValue({ base: true, md: true, lg: false });
  const [isMinimized, setIsMinimized] = useState(shouldMinimize);

  useEffect(() => {
    setIsMinimized(shouldMinimize);
  }, [shouldMinimize]); // Add shouldMinimize to the dependency array
  
  const iconColor = useColorModeValue("#3182CE", "#63B3ED");
  const menuItemColor = useColorModeValue("#3182CE", "#63B3ED");
  const bgColor = useColorModeValue("white", "black");
  const mainMenuBgColor = useColorModeValue("white", "black");
  const borderColor = useColorModeValue("gray.700", "gray.700");
  const bottomBgColor = useColorModeValue("white", "black");
  const accountColor = useColorModeValue("blue.500", "blue.300");
  const helpColor = useColorModeValue("green.500", "green.300");
  const settingsColor = useColorModeValue("purple.500", "purple.300");

  const menuItems = [
    { name: t("Agents"), icon: FaUserFriends, color: menuItemColor, subItems: [
      { name: t("Dashboard"), icon: FaChartBar, color: "blue.400", to: '/dashboard' },
      { name: t("Profiles"), icon: FaIdCard, color: "blue.400", to: '/profiles' },
      { name: t("Voice"), icon: FaMicrophone, color: "blue.400", to: '/voice' }
    ]},
    { name: t("VoIP"), icon: FaPhoneAlt, color: menuItemColor, subItems: [
      { name: t("Call History"), icon: FaHistory, color: "green.400", to: '/callHistory' },
      { name: t("Endpoints"), icon: FaNetworkWired, color: "green.400", to: '/endpoints' },
      { name: t("IVRs"), icon: FaPhone, color: "green.400", to: '/ivrs' }
    ]}
  ];

  useEffect(() => {
    localStorage.setItem('activeMenus', JSON.stringify(activeMenus));
  }, [activeMenus]);

  const handleMenuClick = (index) => {
    if (!isMinimized) {
      setActiveMenus(prevActiveMenus => {
        if (prevActiveMenus.includes(index)) {
          return prevActiveMenus.filter(item => item !== index);
        } else {
          return [...prevActiveMenus, index];
        }
      });
    }
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <Box 
      width={isMinimized ? "80px" : "200px"} 
      bg={bgColor} 
      height="100vh" 
      p={4} 
      display="flex" 
      flexDirection="column" 
      borderRight="1px" 
      borderColor={useColorModeValue("gray.200", "gray.700")}
      transition="width 0.3s"
      position="relative"
      zIndex={1000}
    >
      <VStack spacing={4} align="stretch" flex={1} overflowY="auto" mb={4}>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <Tooltip label={isMinimized ? item.name : ""} placement="right">
              <Button 
                justifyContent={isMinimized ? "center" : "flex-start"}
                variant="ghost" 
                fontWeight="bold" 
                leftIcon={<item.icon size="0.9em" color={iconColor} />}
                bg={mainMenuBgColor}
                _hover={{ bg: mainMenuBgColor }}
                mb={1}
                onClick={() => handleMenuClick(index)}
                w="100%"
                p={2}
                h="auto"
                fontSize="0.9em"
              >
                {!isMinimized && item.name}
              </Button>
            </Tooltip>
            {(isMinimized || activeMenus.includes(index)) && item.subItems.map((subItem, subIndex) => (
              <Tooltip key={subIndex} label={isMinimized ? subItem.name : ""} placement="right">
                <Link to={subItem.to} style={{ textDecoration: 'none' }}>
                  <Button
                    justifyContent={isMinimized ? "center" : "flex-start"}
                    variant="ghost"
                    fontSize="sm"
                    leftIcon={<subItem.icon color={subItem.color} />}
                    w="100%"
                    pl={isMinimized ? 2 : 8}
                    py={1}
                    h="auto"
                    minH={0}
                    animation="fadeIn 0.3s ease-in-out"
                  >
                    {!isMinimized && subItem.name}
                  </Button>
                </Link>
              </Tooltip>
            ))}
          </React.Fragment>
        ))}
      </VStack>
      <Divider my={4} borderColor={borderColor} />
      <Flex 
        justifyContent={isMinimized ? "center" : "space-between"} 
        mt="auto" 
        position="sticky" 
        bottom={0} 
        bg={bottomBgColor} 
        pt={2}
        pb={2}
        flexDirection={isMinimized ? "column" : "row"}
        zIndex={1001}
      >
        <Flex flexDirection={isMinimized ? "column" : "row"}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label={t("Account")}
              icon={<FaUser />}
              variant="ghost"
              color={accountColor}
              mb={isMinimized ? 2 : 0}
              mr={isMinimized ? 0 : 2}
            />
            <MenuList>
              <MenuItem as={Link} to="/account" icon={<FaCog />}>{t("Account Settings")}</MenuItem>
              <MenuItem as={Link} to="/subscription" icon={<FaMoneyBillWave />}>{t("Subscription")}</MenuItem>
              <MenuItem onClick={handleLogout} icon={<FaSignOutAlt />}>{t("Log Out")}</MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label={t("Help")}
              icon={<FaQuestionCircle />}
              variant="ghost"
              color={helpColor}
              mb={isMinimized ? 2 : 0}
              mr={isMinimized ? 0 : 2}
            />
            <MenuList>
              <MenuItem as={Link} to="/help" icon={<FaQuestionCircle />}>{t("Help Page")}</MenuItem>
              <MenuItem as={Link} to="/contact" icon={<FaEnvelope />}>{t("Contact Us")}</MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label={t("Settings")}
              icon={<FaCog />}
              variant="ghost"
              color={settingsColor}
              mb={isMinimized ? 2 : 0}
              mr={isMinimized ? 0 : 2}
            />
            <MenuList>
              <MenuItem as={Link} to="/settings" icon={<FaCog />}>{t("Settings")}</MenuItem>
              <MenuItem onClick={toggleColorMode} icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}>
                {colorMode === 'light' ? t("Dark Mode") : t("Light Mode")}
              </MenuItem>
            </MenuList>
          </Menu>
          <LanguageSelector />
          <IconButton
            icon={isMinimized ? <FaChevronRight /> : <FaChevronLeft />}
            aria-label={isMinimized ? "Expand menu" : "Minimize menu"}
            onClick={toggleMinimize}
            variant="ghost"
            mb={isMinimized ? 2 : 0}
            ml={isMinimized ? 0 : 2}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const TopMenuBar = () => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue("white", "black");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Flex 
      as="nav" 
      bg={bgColor} 
      borderBottom="1px" 
      borderColor={borderColor} 
      py={2} 
      px={4}
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex alignItems="center">
        <Box mr={6}>
        </Box>

        <Box mr={1}>
        </Box>

          <Logo size="md" />
        
      </Flex>
      <Flex alignItems="center">
        <Flex alignItems="center" bg={useColorModeValue("gray.100", "gray.700")} p={2} borderRadius="md">
          <Tooltip label={t("Online Agents")}>
            <Flex alignItems="center" mr={4}>
              <Box bg={useColorModeValue("green.100", "green.700")} p={2} borderRadius="md" mr={2}>
                <FaHeadset color={useColorModeValue("green.500", "green.200")} />
              </Box>
              <Text fontSize="sm" fontWeight="medium" color={useColorModeValue("green.500", "green.200")}>5</Text>
            </Flex>
          </Tooltip>
          <Tooltip label={t("Active Calls")}>
            <Flex alignItems="center" mr={4}>
              <Box bg={useColorModeValue("blue.100", "blue.700")} p={2} borderRadius="md" mr={2}>
                <FaPhone color={useColorModeValue("blue.500", "blue.200")} />
              </Box>
              <Text fontSize="sm" fontWeight="medium" color={useColorModeValue("blue.500", "blue.200")}>3</Text>
            </Flex>
          </Tooltip>
          <Tooltip label={t("Active Campaigns")}>
            <Flex alignItems="center">
              <Box bg={useColorModeValue("orange.100", "orange.700")} p={2} borderRadius="md" mr={2}>
                <FaPlayCircle color={useColorModeValue("orange.500", "orange.200")} />
              </Box>
              <Text fontSize="sm" fontWeight="medium" color={useColorModeValue("orange.500", "orange.200")}>2</Text>
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

function App() {
  const bgColor = useColorModeValue("white", "black");
  const textColor = useColorModeValue("gray.800", "white");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const toast = useToast();
  const [clientSecret, setClientSecret] = useState(null);
  const [options, setOptions] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the setup intent client secret from your server
    const fetchSetupIntentClientSecret = async () => {
      try {
        const response = await fetch(apiUrl('/api/subscription/create-setup-intent'), {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include', // Include credentials for authentication
        });
        const data = await response.json();
        if (data.clientSecret) {
          setClientSecret(data.clientSecret);
        } else {
          console.error('No client secret received');
        }
      } catch (error) {
        console.error('Error fetching setup intent client secret:', error);
      }
    };

    if (isLoggedIn) {
      fetchSetupIntentClientSecret();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (clientSecret) {
      setOptions({
        clientSecret: clientSecret,
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
          },
        },
        loader: 'auto',
      });
    }
  }, [clientSecret]);

  const handleLogout = useCallback(async () => {
    try {
      const response = await fetch(apiUrl('/api/auth/logout'), {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setUser(null);
        setIsLoggedIn(false);
        toast({
          title: "Logged out",
          description: "You have been successfully logged out.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Could not log out. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [setUser, setIsLoggedIn, toast]);

  const fetchUser = useCallback(async () => {
    if (typeof window !== 'undefined' && window.location.pathname !== '/') {
      try {
        const response = await fetch(apiUrl('/api/auth/user'), {
          credentials: 'include'
        });
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          setIsLoggedIn(true);
        } else {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setIsLoggedIn(false);
      }
    }
  }, [setUser, setIsLoggedIn]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleLogin = useCallback(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Flex flexDirection="column" height="100vh" overflow="hidden">
      {isLoggedIn && <TopMenuBar />}
      <Flex flex={1} overflow="hidden">
        {isLoggedIn && <LeftPanel handleLogout={handleLogout} />}
        <Box flex={1} bg={bgColor} color={textColor} overflowY="auto">
          <Routes>
            <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
            <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} onSignUp={() => isSignUpEnabled ? navigate('/signup') : null} />} />
            <Route path="/signup" element={isLoggedIn ? <Navigate to="/dashboard" /> : (isSignUpEnabled ? <SignUp /> : <Navigate to="/login" />)} />
            <Route path="/help" element={<Help />} />
            <Route path="/subscription-success" element={<SubscriptionSuccess />} />
            {isLoggedIn ? (
              <Route
                path="/*"
                element={
                  clientSecret && options ? (
                    <Elements stripe={stripePromise} options={options}>
                      <Routes>
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/account" element={(
                          <VStack spacing={8} p={8} align="center">
                            <Text>Account Settings for {user?.email}</Text>
                            <ChangePasswordForm />
                          </VStack>
                        )} />
                        <Route path="/profiles" element={<AgentProfiles />} />
                        <Route path="/voice" element={<VoiceManagement />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/callHistory" element={<CallHistory />} />
                        <Route path="/endpoints" element={<Endpoints />} />
                        <Route path="/ivrs" element={<IVRs />} />
                        <Route path="/subscription" element={<Subscription />} />
                        <Route path="/changePlan" element={<ChangePlan />} />
                      </Routes>
                    </Elements>
                  ) : (
                    <Box p={8}>Loading Stripe Elements...</Box>
                  )
                }
              />
            ) : (
              <Route path="*" element={<Navigate to="/login" />} />
            )}
          </Routes>
        </Box>
      </Flex>
    </Flex>
  );
}

const AlphaBanner = () => {
  const bgColor = useColorModeValue("red.500", "red.700");
  const textColor = useColorModeValue("white", "gray.100");
  const glowColor = useColorModeValue("white", "black");

  return (
    <Box
      position="fixed"
      top="-5px"
      right="-90px"
      width="250px"
      transform="rotate(45deg)"
      bg={bgColor}
      color={textColor}
      py={0.1}
      textAlign="right"
      paddingRight="85px"
      fontWeight="bold"
      zIndex={9999}
      boxShadow={`0 0 10px 3px ${glowColor}`}
    >
      Alpha
    </Box>
  );
};

function AppWithRouter() {
  return (
    <ChakraProvider theme={{
      ...theme,
      config: {
        ...theme.config,
        initialColorMode: 'dark',
        useSystemColorMode: false,
      },
      styles: {
        global: {
          body: {
            bg: 'black',
            color: 'white',
          },
        },
      },
    }}>
      <Router>
        <AlphaBanner />
        <App />
      </Router>
    </ChakraProvider>
  );
}

export default AppWithRouter;
