import React, { useState } from 'react';
import { Box, Heading, Text, Container, SimpleGrid, VStack, Input, Textarea, Button, Icon, Flex, Link, useToast } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import Logo from '../components/Logo';
import { Helmet } from 'react-helmet';

const ContactInfo = ({ icon, title, content }) => (
  <VStack align="start" spacing={2}>
    <Icon as={icon} boxSize={6} color="blue.500" />
    <Text fontWeight="bold">{title}</Text>
    <Text>{content}</Text>
  </VStack>
);

const Contact = () => {
  const { t } = useTranslation();
  const bgColor = 'black';
  const cardBg = 'gray.800';
  const textColor = 'gray.100';
  const toast = useToast();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    toast({
      title: t("Message Sent"),
      description: t("We've received your message and will get back to you soon."),
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <Box bg={bgColor} color={textColor} minH="100vh" display="flex" flexDirection="column">
      <Helmet>
        <title>Contact VoxAI - Get in Touch with Our AI Customer Service Experts</title>
        <meta name="description" content="Have questions about VoxAI's AI-powered phone agents? Contact our team of experts for personalized support and learn how we can transform your customer service experience." />
        <link rel="canonical" href="https://voxai.app/contact" />
        <meta property="og:title" content="Contact VoxAI - Get in Touch with Our AI Customer Service Experts" />
        <meta property="og:description" content="Have questions about VoxAI's AI-powered phone agents? Contact our team of experts for personalized support and learn how we can transform your customer service experience." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://voxai.app/contact" />
        <meta property="og:image" content="https://voxai.app/contact-og-image.jpg" />
        <meta property="og:site_name" content="VoxAI" />
        {/* Google Tag Manager */}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MQRFQXKF');`}</script>
        {/* End Google Tag Manager */}
      </Helmet>
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe 
          src="https://www.googletagmanager.com/ns.html?id=GTM-MQRFQXKF"
          height="0" 
          width="0" 
          style={{display: 'none', visibility: 'hidden'}}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}
      <Container maxW="container.xl" py={20} flex="1">
        <VStack spacing={10} align="stretch">
          <Flex justifyContent="center" mb={6}>
            <RouterLink to="/">
              <Logo size="4xl" text="VoxAI" />
            </RouterLink>
          </Flex>
          <Box textAlign="center">
            <Heading as="h1" size="2xl" mb={4}>
              {t("Contact Us")}
            </Heading>
            <Text fontSize="xl">
              {t("We're here to help and answer any question you might have")}
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <VStack spacing={8} align="stretch">
              <Box bg={cardBg} p={8} borderRadius="lg" boxShadow="md">
                <VStack spacing={6} align="stretch">
                  <ContactInfo 
                    icon={FaEnvelope} 
                    title={t("Email")} 
                    content="support@voxai.app" 
                  />
                  <ContactInfo 
                    icon={FaPhone} 
                    title={t("Phone")} 
                    content="+1 (873) 700-5060" 
                  />
                </VStack>
              </Box>
            </VStack>

            <VStack as="form" onSubmit={handleSubmit} spacing={4} align="stretch">
              <Input 
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder={t("Your Name")} 
                size="lg" 
                required
              />
              <Input 
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder={t("Your Email")} 
                size="lg" 
                type="email" 
                required
              />
              <Input 
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                placeholder={t("Subject")} 
                size="lg" 
                required
              />
              <Textarea 
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder={t("Your Message")} 
                size="lg" 
                rows={5} 
                required
              />
              <Button
                type="submit"
                colorScheme="purple"
                size="lg"
                fontWeight="bold"
                px={8}
                borderRadius="full"
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
              >
                {t("Send Message")}
              </Button>
            </VStack>
          </SimpleGrid>
        </VStack>
      </Container>
      <Footer />
    </Box>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box as="footer" bg="gray.900" color="gray.300" py={8} mt={20}>
      <Container maxW="container.xl">
        <Flex direction={["column", "row"]} justify="space-between" align="center">
          <VStack align="flex-start" mb={[4, 0]}>
            <Text fontWeight="bold" mb={2}>{t("Company")}</Text>
            <Link as={RouterLink} to="/about" color="gray.500" _hover={{ color: "white" }}>{t("About Us")}</Link>
            <Link as={RouterLink} to="/contact" color="gray.500" _hover={{ color: "white" }}>{t("Contact")}</Link>
            <Link as={RouterLink} to="/privacy-policy" color="gray.500" _hover={{ color: "white" }}>{t("Privacy Policy")}</Link>
          </VStack>
          <VStack align="flex-start" mb={[4, 0]}>
            <Text fontWeight="bold" mb={2}>{t("Resources")}</Text>
            <Link as={RouterLink} to="/documentation" color="gray.500" _hover={{ color: "white" }}>{t("Documentation")}</Link>
            <Link as={RouterLink} to="/blog" color="gray.500" _hover={{ color: "white" }}>{t("Blog")}</Link>
          </VStack>
          <VStack align="flex-start">
            <Text fontWeight="bold" mb={2}>{t("Connect")}</Text>
            <Link href="#" color="gray.500" _hover={{ color: "white" }}>X</Link>
          </VStack>
        </Flex>
        <Text mt={8} textAlign="center" fontSize="sm" color="gray.500">
            © {new Date().getFullYear()} {"voxai"}. {t("All rights reserved.")}
        </Text>
      </Container>
    </Box>
  );
};

export default Contact;
