import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const IVRs = () => {
  const { t } = useTranslation();

  return (
    <Box p={5}>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        {t("IVRs")}
      </Text>
      <Text>
        {t("IVR management will be implemented here.")}
      </Text>
    </Box>
  );
};

export default IVRs;
