import React, { useState } from 'react';
import { useColorModeValue, Box, Button, FormControl, FormLabel, Input, VStack, Text, Link, useToast, useColorMode, HStack, IconButton, Flex, useBreakpointValue, Menu, MenuButton, MenuList, MenuItem, Collapse } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SunIcon, MoonIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaLanguage } from 'react-icons/fa';
import TermsOfService from './components/TermsOfService';
import { apiUrl } from './config/api';
import Logo from './components/Logo';
import { keyframes } from '@emotion/react';
import { isSignUpEnabled } from './config/constants';
import { Link as RouterLink } from 'react-router-dom';

const borderAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const animatedButtonStyles = {
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    right: '-2px',
    bottom: '-2px',
    background: 'linear-gradient(45deg, #00BFFF, #1E90FF, #4169E1, #0000FF, #8A2BE2, #9932CC, #8B008B, #00BFFF)',
    backgroundSize: '400%',
    zIndex: -1,
    filter: 'blur(5px)',
    animation: `${borderAnimation} 20s linear infinite`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    borderRadius: 'inherit',
  },
  '&:hover::before': {
    opacity: 1,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'inherit',
    borderRadius: 'inherit',
    zIndex: -1,
  },
};

const Login = ({ onToggle, onLogin, onNavigateToLanding, onSignUp }) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTOSOpen, setIsTOSOpen] = useState(false);
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("linear-gradient(to bottom right, #FFFFFF, #F0F8FF)", "black");
  const textColor = useColorModeValue("gray.800", "white");

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(apiUrl('/api/auth/login'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // This is important for including cookies
        body: JSON.stringify({ email, password }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'An error occurred during login');
      }
      
      const data = await response.json();
      console.log('Login response:', data);
      if (!data.user || !data.user.id) {
        throw new Error('User data not received from server');
      }
      
      toast({
        title: "Login successful.",
        description: "You've been logged in.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      console.log('Calling onLogin with user data:', data.user);
      onLogin(data.user);
      // Remove the navigation from here as it will be handled in App.js
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Box maxWidth="400px" width="100%">
      
        <Box 
          borderRadius="0" 
          p={8} 
          boxShadow="lg" 
          bg={bgColor}
          position="relative"
          width="400px"
          height="400px"
          transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
          _hover={{ transform: "scale(1.02)", boxShadow: "xl" }}
          _active={{ transform: "scale(0.98)" }}
        >
        
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <HStack justify="space-between">
              <RouterLink to="/">
                <Flex alignItems="center" cursor="pointer">
                  <Logo size="md" />
                </Flex>
              </RouterLink>
              <HStack>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label={t("Language")}
                    icon={<FaLanguage />}
                    variant="ghost"
                  />
                  <MenuList>
                    <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
                    <MenuItem onClick={() => handleLanguageChange('fr')}>Français</MenuItem>
                  </MenuList>
                </Menu>
                <IconButton
                  icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                  onClick={toggleColorMode}
                  aria-label={`Toggle ${colorMode === 'light' ? 'Dark' : 'Light'} Mode`}
                  variant="ghost"
                />
              </HStack>
            </HStack>
            <FormControl isRequired>
              <FormLabel color={textColor}>{t("Email")}</FormLabel>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} size="sm" />
            </FormControl>
            <FormControl isRequired>
              <FormLabel color={textColor}>{t("Password")}</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} size="sm" />
            </FormControl>
            <Button 
              type="submit" 
              colorScheme="blue" 
              isLoading={isLoading} 
              size="sm"
              variant="outline" 
              borderColor="blue.500" 
              color="white" 
              _hover={{ bg: "black" }}
              sx={animatedButtonStyles}
              fontSize={useBreakpointValue({ base: "xs", md: "sm" })}
            >
              <Text fontWeight="bold">{t("Log In")}</Text>
            </Button>
            <Box>
              <Button
                rightIcon={isTOSOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                onClick={() => setIsTOSOpen(!isTOSOpen)}
                variant="link"
                size="sm"
                color={textColor}
              >
                {t("Terms of Service")}
              </Button>
              <Collapse in={isTOSOpen} animateOpacity>
                <TermsOfService />
              </Collapse>
            </Box>
            {isSignUpEnabled && (
              <Text color={textColor}>
                {t("Don't have an account?")} <Link color="blue.500" onClick={onSignUp}>{t("Sign Up")}</Link>
              </Text>
            )}
          </VStack>
        </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default Login;
