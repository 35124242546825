import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  useDisclosure,
  Flex,
  IconButton,
  Tooltip,
  Heading,
  Link,
  NumberInput,
  NumberInputField,
  Collapse,
  Text,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaSearch, FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown, FaSave } from 'react-icons/fa';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { apiUrl } from './config/api';

const Endpoints = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [endpoints, setEndpoints] = useState([]);
  const [sortedEndpoints, setSortedEndpoints] = useState([]);
  const [currentEndpoint, setCurrentEndpoint] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKeys, setSortKeys] = useState(['name']);
  const [sortOrders, setSortOrders] = useState(['asc']);
  const [profiles, setProfiles] = useState([]);
  const [regions, setRegions] = useState([]);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

  const pageTitle = t("Endpoints");

  const fetchProfiles = useCallback(async () => {
    try {
      const response = await fetch(apiUrl('/api/profiles'), {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const profilesData = await response.json();
      setProfiles(profilesData);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      toast({
        title: "Error",
        description: `Failed to fetch profiles: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  const fetchRegions = useCallback(async () => {
    try {
      const response = await fetch(apiUrl('/api/regions'), {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const regionsData = await response.json();
      setRegions(regionsData);
    } catch (error) {
      console.error('Error fetching regions:', error);
      toast({
        title: "Error",
        description: `Failed to fetch regions: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchProfiles();
    fetchRegions();
  }, [fetchProfiles, fetchRegions]);

  const fetchEndpoints = useCallback(async () => {
    try {
      const response = await fetch(apiUrl('/api/endpoints'), {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const endpointsData = await response.json();
      setEndpoints(endpointsData);
      setSortedEndpoints(sortAndFilterEndpoints(endpointsData, sortKeys, sortOrders, searchTerm));
      console.log('Fetched endpoints:', endpointsData);
    } catch (error) {
      console.error('Error fetching endpoints:', error);
      toast({
        title: "Error",
        description: `Failed to fetch endpoints: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [sortKeys, sortOrders, searchTerm, toast]);

  useEffect(() => {
    fetchEndpoints();
  }, [fetchEndpoints]);

  const sortAndFilterEndpoints = (endpointsToSort, keys, orders, filter) => {
    return [...endpointsToSort]
      .filter(endpoint => 
        endpoint.name.toLowerCase().includes(filter.toLowerCase()) ||
        endpoint.sip_username.toLowerCase().includes(filter.toLowerCase()) ||
        endpoint.sip_registrar.toLowerCase().includes(filter.toLowerCase()) ||
        endpoint.endpoint_home_region.toLowerCase().includes(filter.toLowerCase())
      )
      .sort((a, b) => {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const order = orders[i];
          if (a[key] < b[key]) return order === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return order === 'asc' ? 1 : -1;
        }
        return 0;
      });
  };

  const handleSort = (key) => {
    const index = sortKeys.indexOf(key);
    let newSortKeys = [...sortKeys];
    let newSortOrders = [...sortOrders];
    if (index === -1) {
      newSortKeys = [key, ...sortKeys.filter(k => k !== key)];
      newSortOrders = ['asc', ...newSortOrders.slice(0, -1)];
    } else {
      newSortOrders[index] = sortOrders[index] === 'asc' ? 'desc' : 'asc';
    }
    setSortKeys(newSortKeys);
    setSortOrders(newSortOrders);
    setSortedEndpoints(sortAndFilterEndpoints(endpoints, newSortKeys, newSortOrders, searchTerm));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSortedEndpoints(sortAndFilterEndpoints(endpoints, sortKeys, sortOrders, event.target.value));
  };

  const handleAddEndpoint = () => {
    setCurrentEndpoint({
      enabled: true,
      name: '',
      sip_username: '',
      sip_password: '',
      sip_protocol: 'UDP',
      sip_registrar: '',
      sip_registrar_port: 5060,
      sip_registeration_enabled: true,
      endpoint_home_region: '',
    });
    onOpen();
  };

  const handleEditEndpoint = (endpoint) => {
    setCurrentEndpoint(endpoint);
    onOpen();
  };

  const handleDeleteEndpoint = async (endpointId) => {
    if (window.confirm('Are you sure you want to delete this endpoint?')) {
      try {
        const response = await fetch(apiUrl(`/api/endpoints/${endpointId}`), {
          method: 'DELETE',
          credentials: 'include',
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete endpoint');
        }
        await fetchEndpoints();
        toast({
          title: "Endpoint deleted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting endpoint:', error);
        toast({
          title: "Error deleting endpoint",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const endpointData = Object.fromEntries(formData.entries());
    
    // Correctly handle switch values
    endpointData.enabled = document.getElementById('enabled').checked;
    endpointData.sip_registeration_enabled = document.getElementById('sip_registeration_enabled').checked;
    endpointData.sip_registrar_port = parseInt(endpointData.sip_registrar_port, 10);

    try {
      const url = currentEndpoint.id
        ? apiUrl(`/api/endpoints/${currentEndpoint.id}`)
        : apiUrl('/api/endpoints');
      
      const method = currentEndpoint.id ? 'PUT' : 'POST';

      console.log('Submitting endpoint data:', endpointData);

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(endpointData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to ${currentEndpoint.id ? 'update' : 'add'} endpoint`);
      }

      toast({
        title: `Endpoint ${currentEndpoint.id ? 'updated' : 'added'}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      onClose();
      await fetchEndpoints();
    } catch (error) {
      console.error('Error submitting endpoint:', error);
      toast({
        title: "Error submitting endpoint",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Subscription cancellation functionality removed

  return (
    <Box p={8}>
      <Heading as="h1" size="xl" mb={3}>{pageTitle}</Heading>
      <Box mb={6} fontSize="md" color="gray.600">
        {t("Endpoints represent the connection points for SIP (Session Initiation Protocol) communication. They define how your system connects to SIP servers or other SIP-enabled devices.")}
        <Box mt={2}>
          <Link href="/help#endpoints" color="blue.500" fontWeight="medium">
            {t("Learn more about Endpoints")}
          </Link>
        </Box>
      </Box>
      <Flex mb={4}>
        <Input
          placeholder={t("Search endpoints...")}
          value={searchTerm}
          onChange={handleSearch}
          mr={2}
        />
        <IconButton
          aria-label="Search"
          icon={<FaSearch />}
          onClick={() => {}}
          mr={2}
        />
        <IconButton
          aria-label="Add Endpoint"
          icon={<FaPlus />}
          onClick={handleAddEndpoint}
          colorScheme="blue"
        />
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t("Enabled")}</Th>
            {['name', 'sip_username', 'proto', 'sip_registrar', 'sip_registrar_port'].map((key) => (
              <Th key={key} onClick={() => handleSort(key)} cursor="pointer">
                <Flex alignItems="center">
                  {t(key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '))}
                  {sortKeys[0] === key && (
                    <Box ml={2}>
                      {sortOrders[0] === 'asc' ? <FaSortUp /> : <FaSortDown />}
                    </Box>
                  )}
                </Flex>
              </Th>
            ))}
            <Th>{t("Destination")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedEndpoints.map((endpoint) => (
            <Tr key={endpoint.id}>
              <Td>
                <Box
                  display="inline-block"
                  px={1}
                  py={0.5}
                  borderRadius="full"
                  bg={endpoint.enabled ? 'green.100' : 'red.100'}
                  color={endpoint.enabled ? 'green.700' : 'red.700'}
                  fontSize="sm"
                  fontWeight="medium"
                >
                  {endpoint.enabled ? 'True' : 'False'}
                </Box>
              </Td>
              <Td>{endpoint.name}</Td>
              <Td>{endpoint.sip_username}</Td>
              <Td>{endpoint.sip_protocol}</Td>
              <Td>{endpoint.sip_registrar}</Td>
              <Td>{endpoint.sip_registrar_port}</Td>
              <Td>{endpoint.destination ? profiles.find(p => p.uuid === endpoint.destination)?.name || 'Unknown' : 'No Routing'}</Td>
              <Td>
                <Tooltip label={t("Edit")}>
                  <IconButton
                    icon={<FaEdit />}
                    size="sm"
                    variant="ghost"
                    mr={2}
                    onClick={() => handleEditEndpoint(endpoint)}
                    aria-label={t("Edit")}
                    color="orange.500"
                  />
                </Tooltip>
                <Tooltip label={t("Delete")}>
                  <IconButton
                    icon={<FaTrash />}
                    size="sm"
                    variant="ghost"
                    onClick={() => handleDeleteEndpoint(endpoint.id)}
                    aria-label={t("Delete")}
                    color="red.500"
                  />
                </Tooltip>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{currentEndpoint?.id ? t("Edit Endpoint") : t("Add Endpoint")}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl display="flex" alignItems="center" mb={4}>
                <FormLabel htmlFor="enabled" mb="0">
                  {t("Enabled")}
                </FormLabel>
                <Switch 
                  id="enabled" 
                  name="enabled" 
                  defaultChecked={currentEndpoint?.enabled}
                  sx={{
                    '& .chakra-switch__track[data-checked]': {
                      backgroundColor: 'green.500',
                    },
                  }}
                />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>{t("Name")}</FormLabel>
                <Input name="name" defaultValue={currentEndpoint?.name} required />
              </FormControl>
              <Flex mb={4}>
                <FormControl isRequired mr={2}>
                  <FormLabel>{t("SIP Username")}</FormLabel>
                  <Input name="sip_username" defaultValue={currentEndpoint?.sip_username} required />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t("SIP Password")}</FormLabel>
                  <Input name="sip_password" type="password" defaultValue={currentEndpoint?.sip_password} required />
                </FormControl>
              </Flex>
              <Flex mb={4}>
                <FormControl isRequired flex="1" mr={2}>
                  <FormLabel>{t("SIP Server")}</FormLabel>
                  <Input name="sip_registrar" defaultValue={currentEndpoint?.sip_registrar} required />
                </FormControl>
                <Flex>
                  <FormControl isRequired mr={2}>
                    <FormLabel>{t("Port")}</FormLabel>
                    <NumberInput
                      min={1}
                      max={65535}
                      defaultValue={currentEndpoint?.sip_registrar_port || 5060}
                      clampValueOnBlur={true}
                      width="90px"
                    >
                      <NumberInputField
                        name="sip_registrar_port"
                        required
                        pattern="\d*"
                        maxLength={7}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
                          const value = parseInt(e.target.value, 10);
                          if (value < 1) e.target.value = '1';
                          if (value > 65535) e.target.value = '65535';
                        }}
                      />
                    </NumberInput>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>{t("Proto")}</FormLabel>
                    <Select 
                      name="sip_protocol" 
                      defaultValue={currentEndpoint?.sip_protocol} 
                      required 
                      width="85px"
                      textTransform="uppercase"
                    >
                      <option value="UDP">UDP</option>
                      <option value="TCP">TCP</option>
                      <option value="TLS">TLS</option>
                      <option value="SCTP">SCTP</option>
                    </Select>
                  </FormControl>
                </Flex>
              </Flex>
              <FormControl display="flex" alignItems="center" mb={4}>
                <FormLabel htmlFor="sip_registeration_enabled" mb="0">
                  {t("SIP Registration Enabled")}
                </FormLabel>
                <Switch 
                  id="sip_registeration_enabled" 
                  name="sip_registeration_enabled" 
                  defaultChecked={currentEndpoint?.sip_registeration_enabled}
                  sx={{
                    '& .chakra-switch__track[data-checked]': {
                      backgroundColor: 'green.500',
                    },
                  }}
                />
              </FormControl>
              <FormControl isRequired mb={4}>
                <FormLabel>{t("Endpoint Home Region")}</FormLabel>
                <Select name="endpoint_home_region" defaultValue={currentEndpoint?.endpoint_home_region} required>
                  {regions.map((region) => (
                    <option key={region.id} value={parseInt(region.id, 10)}>
                      {region.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>{t("Destination")}</FormLabel>
                <Select 
                  name="destination" 
                  defaultValue={currentEndpoint?.destination || ''}
                  onChange={(e) => {
                    const form = e.target.form;
                    form.destination.value = e.target.value || null;
                  }}
                >
                  <option value="">{t("No Routing")}</option>
                  {profiles.map((profile) => (
                    <option key={profile.uuid} value={profile.uuid}>
                      {profile.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Box mb={4}>
                <Divider my={4} />
                <Button
                  onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
                  rightIcon={isAdvancedOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  variant="ghost"
                >
                  {t("Advanced")}
                </Button>
                <Collapse in={isAdvancedOpen} animateOpacity>
                  <Box
                    p="40px"
                    color="white"
                    mt="4"
                    bg="teal.500"
                    rounded="md"
                    shadow="md"
                  >
                    <Text>{t("Advanced configuration options will be added here.")}</Text>
                  </Box>
                </Collapse>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button 
                colorScheme="blue" 
                mr={3} 
                type="submit"
                leftIcon={<FaSave />}
              >
                {currentEndpoint?.id ? t("Update") : t("Add")}
              </Button>
              <Button onClick={onClose}>{t("Cancel")}</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Endpoints;
