import React, { useState, useEffect, useCallback } from 'react';
import { Textarea } from '@chakra-ui/react';
import { debounce } from 'lodash';

const DebouncedTextarea = React.memo(({ value, onChange, ...props }) => {
  const [localValue, setLocalValue] = useState(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((newValue) => {
      onChange(newValue);
    }, 300),
    [onChange]
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    debouncedOnChange(newValue);
  };

  return <Textarea value={localValue} onChange={handleChange} {...props} />;
});

export default DebouncedTextarea;
