import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { debounce } from 'lodash';
import DebouncedTextarea from './components/DebouncedTextarea';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useDisclosure,
  Flex,
  IconButton,
  Heading,
  Link,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  useMediaQuery,
  SimpleGrid,
  Text,
  VStack,
  HStack,
  Icon,
  Switch,
  useColorModeValue
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import ChatModal from './components/ChatModal';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown, FaPhone, FaComments, FaSave, FaHeadset, FaWrench, FaInfoCircle, FaUtensils, FaTaxi, FaUser, FaUserPlus, FaCalendarCheck, FaArrowLeft, FaPhoneAlt, FaPhoneVolume } from 'react-icons/fa';
import VoicePlayButton from './components/VoicePlayButton';
import { apiUrl } from './config/api';

const DebouncedInput = React.memo(({ value, onChange, ...props }) => {
  const [localValue, setLocalValue] = useState(value);

  const debouncedOnChange = useMemo(
    () => debounce((newValue) => {
      onChange(newValue);
    }, 300),
    [onChange]
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    debouncedOnChange(newValue);
  };

  return <Input value={localValue} onChange={handleChange} {...props} />;
});

// Suppress ResizeObserver loop limit exceeded error
const resizeObserverLoopErrRe = /^ResizeObserver loop (limit exceeded|completed with undelivered notifications)/;
const originalConsoleError = console.error;
console.error = (...args) => {
  if (args.length > 0 && typeof args[0] === 'string' && resizeObserverLoopErrRe.test(args[0])) {
    return;
  }
  originalConsoleError.apply(console, args);
};



const AgentProfiles = React.memo(() => {
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState([]);
  const [currentProfile, setCurrentProfile] = useState({});
  const [voices, setVoices] = useState([]);
  const [endpoints, setEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState('');
  const [unusedEndpoints, setUnusedEndpoints] = useState([]);
  const [sortKeys, setSortKeys] = useState(['name']);
  const [sortOrders, setSortOrders] = useState(['asc']);
  const { isOpen, onOpen, onClose: originalOnClose } = useDisclosure();
  const { isOpen: isTypeModalOpen, onOpen: onTypeModalOpen, onClose: onTypeModalClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [currentChatProfile, setCurrentChatProfile] = useState(null);
  const [viewMode, setViewMode] = useState(isLargerThan1280 ? 'table' : 'card');
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const cardBgColor = useColorModeValue("white", "gray.900");
  const cardTextColor = useColorModeValue("gray.800", "white");
  const cardDescriptionColor = useColorModeValue("gray.600", "gray.300");
  const cardDetailColor = useColorModeValue("gray.700", "gray.100");
  const onClose = () => {
    if (playingVoice !== null) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setPlayingVoice(null);
    }
    originalOnClose();
  };
  const toast = useToast();
  const [playingVoice, setPlayingVoice] = useState(null);
  const audioRef = useRef(new Audio());

  // Add a title for the Agent Profiles page
  const pageTitle = t("Agents Profiles");

  const fetchProfiles = useCallback(async () => {
    try {
      const response = await fetch(apiUrl('/api/profiles'), {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const profilesData = await response.json();
      setProfiles(profilesData);
      console.log('Fetched profiles:', profilesData); // Log fetched profiles
    } catch (error) {
      console.error('Error fetching profiles:', error);
      toast({
        title: "Error",
        description: `Failed to fetch profiles: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);


  const fetchVoices = useCallback(async () => {
    try {
      const providers = ['PlayHT', 'Deepgram'];
      const allVoices = [];

      for (const provider of providers) {
        const response = await fetch(apiUrl(`/api/voices/provider/${provider}`), {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const voicesData = await response.json();
        allVoices.push(...voicesData.map(voice => ({...voice, provider_name: provider})));
      }

      setVoices(allVoices);
      console.log('Fetched voices:', allVoices); // Log fetched voices
    } catch (error) {
      console.error('Error fetching voices:', error);
      toast({
        title: "Error",
        description: `Failed to fetch voices: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  const fetchEndpoints = useCallback(async () => {
    try {
      const response = await fetch(apiUrl('/api/endpoints'), {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const endpointsData = await response.json();
      setEndpoints(endpointsData);
      console.log('Fetched endpoints:', endpointsData);

      // Filter unused endpoints
      setUnusedEndpoints(endpointsData);

    } catch (error) {
      console.error('Error fetching endpoints:', error);
      toast({
        title: "Error",
        description: `Failed to fetch endpoints: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        fetchProfiles(),
        fetchVoices(),
        fetchEndpoints()
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast({
        title: "Error",
        description: `Failed to fetch data: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast, fetchProfiles, fetchVoices, fetchEndpoints]);

  useEffect(() => {
    fetchData();

    const audio = audioRef.current;
    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [fetchData]);

  useEffect(() => {
    if (endpoints.length > 0 && profiles.length > 0) {
      const usedEndpointIds = profiles.map(profile => profile.endpoint_id).filter(Boolean);
      const unusedEndpointsData = endpoints.filter(endpoint => !usedEndpointIds.includes(endpoint.id));
      setUnusedEndpoints(unusedEndpointsData);
    }
  }, [endpoints, profiles]);

  const sortAndFilterProfiles = (profilesToSort, keys, orders) => {
    return [...profilesToSort].sort((a, b) => {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const order = orders[i];
        if (key === 'extension') {
          const aExtension = a.extension || '';
          const bExtension = b.extension || '';
          if (aExtension < bExtension) return order === 'asc' ? -1 : 1;
          if (aExtension > bExtension) return order === 'asc' ? 1 : -1;
        } else {
          if (a[key] < b[key]) return order === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return order === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
  };

  const profilesWithExtensions = useMemo(() => {
    return profiles.map(profile => {
      const endpoint = endpoints.find(e => e.destination === profile.uuid);
      return {
        ...profile,
        extension: endpoint ? `${endpoint.name} / ${endpoint.sip_username}` : ''
      };
    });
  }, [profiles, endpoints]);

  const handleSort = (key) => {
    const index = sortKeys.indexOf(key);
    let newSortKeys = [...sortKeys];
    let newSortOrders = [...sortOrders];
    if (index === -1) {
      newSortKeys = [key, ...sortKeys.filter(k => k !== key)];
      newSortOrders = ['asc', ...newSortOrders.slice(0, -1)];
    } else {
      newSortOrders[index] = sortOrders[index] === 'asc' ? 'desc' : 'asc';
    }
    setSortKeys(newSortKeys);
    setSortOrders(newSortOrders);
  };



  const handleEditProfile = (profile) => {
    const associatedEndpoint = endpoints.find(e => e.destination === profile.uuid);
    setCurrentProfile({
      ...profile,
      memories: profile.memories || '',
      enable_transfers: profile.enable_transfers === true,
      enable_hangups: profile.enable_hangups === true,
      subtypeData: profile.subtypeData || {}
    });
    setSelectedEndpoint(associatedEndpoint ? associatedEndpoint.id : '');
    onOpen();
  };

  const handleDeleteProfile = async (profileId) => {
    if (window.confirm('Are you sure you want to delete this profile?')) {
      try {
        const response = fetch(apiUrl(`/api/profiles/${profileId}`), {
          method: 'DELETE',
          credentials: 'include', // Include cookies for authentication
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete profile');
        }
        await fetchData();
        toast({
          title: "Profile deleted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting profile:', error);
        toast({
          title: "Error deleting profile",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const profileData = Object.fromEntries(formData.entries());
    
    // Ensure boolean values are correctly set
    profileData.enable_hangups = currentProfile.enable_hangups;
    profileData.enable_transfers = currentProfile.enable_transfers;

    // Include the current endpoint if selected
    if (selectedEndpoint) {
      const endpoint = endpoints.find(e => e.id === selectedEndpoint);
      if (endpoint) {
        profileData.endpoint_home_region = endpoint.endpoint_home_region;
        profileData.endpoint_id = endpoint.id;
      }
    } else {
      profileData.endpoint_home_region = null;
      profileData.endpoint_id = null;
    }

    // Add privacy settings
    profileData.privacy = {
      data_retention: profileData.data_retention,
      data_anonymization: profileData.data_anonymization === 'on',
      call_recording: profileData.call_recording === 'true',
      call_transcription: profileData.call_transcription === 'true',
    };

    // Add memories
    profileData.memories = profileData.memories || '';
    profileData.enable_transfers = profileData.enable_transfers === 'true' || profileData.enable_transfers === true;
    profileData.enable_hangups = profileData.enable_hangups === true;
    if (profileData.enable_transfers) {
      profileData.transfer_directory_source = currentProfile.transfer_directory_source;
      if (profileData.transfer_directory_source === 'file') {
        profileData.transfer_directory = currentProfile.transfer_directory;
      } else if (profileData.transfer_directory_source === 'url') {
        profileData.transfer_directory_url = currentProfile.transfer_directory_url;
      } else if (profileData.transfer_directory_source === 'manual') {
        profileData.transfer_directory_manual = currentProfile.transfer_directory_manual;
      }
    }

    // Add subtype-specific data
    profileData.subtypeData = currentProfile.subtypeData || {};
    switch (profileData.subtype) {
      case 'Receptionist':
        profileData.subtypeData = {
          company_name: profileData.company_name,
          business_hours: profileData.business_hours,
          use_specific_greeting: currentProfile.subtypeData.use_specific_greeting,
          greeting_message: currentProfile.subtypeData.use_specific_greeting ? profileData.greeting_message : null,
          industry: profileData.industry === 'Other' ? profileData.otherIndustry : profileData.industry
        };
        break;
      case 'Technical Support':
        profileData.subtypeData = {
          product_name: profileData.product_name,
          support_level: profileData.support_level,
          knowledge_base_url: profileData.knowledge_base_url
        };
        break;
      case 'Information Line':
        profileData.subtypeData = {
          topic: profileData.topic,
          information_source: profileData.information_source,
          update_frequency: profileData.update_frequency
        };
        break;
      case 'Restaurant Order':
        profileData.subtypeData = {
          restaurant_name: profileData.restaurant_name,
          cuisine_type: profileData.cuisine_type,
          menu_url: profileData.menu_url
        };
        break;
      case 'Taxi dispatcher':
        profileData.subtypeData = {
          company_name: profileData.company_name,
          service_area: profileData.service_area,
          pricing_model: profileData.pricing_model
        };
        break;
      case 'Cold Caller lead generator':
        profileData.subtypeData = {
          campaign_name: profileData.campaign_name,
          target_audience: profileData.target_audience,
          script: profileData.script
        };
        break;
      case 'Appointment Confirmation':
        profileData.subtypeData = {
          business_type: profileData.business_type,
          confirmation_method: profileData.confirmation_method,
          reminder_schedule: profileData.reminder_schedule
        };
        break;
      case 'Custom':
        profileData.subtypeData = {
          custom_field1: profileData.custom_field1
        };
        break;
      default:
        // Handle any unexpected subtype
        console.warn(`Unexpected subtype: ${profileData.subtype}`);
        break;
    }

    console.log('Profile data being submitted:', profileData);

    if (currentProfile?.id) {
      profileData.id = currentProfile.id;
    }

    const requiredFields = ['name', 'gender', 'voice', 'type', 'subtype'];
    for (const field of requiredFields) {
      if (!profileData[field]) {
        toast({
          title: `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    // Check required fields for each subtype
    const subtypeRequiredFields = {
      'Receptionist': ['company_name'],
      'Technical Support': ['product_name', 'support_level'],
      'Information Line': ['topic', 'information_source'],
      'Restaurant Order': ['restaurant_name'],
      'Taxi dispatcher': ['company_name', 'service_area', 'pricing_model'],
      'Cold Caller lead generator': ['campaign_name', 'target_audience', 'script'],
      'Appointment Confirmation': ['business_type', 'confirmation_method', 'reminder_schedule'],
    };

    if (subtypeRequiredFields[profileData.subtype]) {
      for (const field of subtypeRequiredFields[profileData.subtype]) {
        if (!profileData.subtypeData[field]) {
          toast({
            title: `${field.charAt(0).toUpperCase() + field.slice(1)} is required for ${profileData.subtype}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }
    }

    try {
      const url = currentProfile && currentProfile.id
        ? apiUrl(`/api/profiles/${currentProfile.id}`)
        : apiUrl('/api/profiles');
      
      const method = currentProfile && currentProfile.id ? 'PUT' : 'POST';

      console.log('Sending profile data:', profileData);

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(profileData),
      });

      console.log('Response status:', response.status);
      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to ${currentProfile && currentProfile.id ? 'update' : 'add'} profile`);
      }

      toast({
        title: `Profile ${currentProfile && currentProfile.id ? 'updated' : 'added'}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      onClose();
      await fetchData();
    } catch (error) {
      console.error('Error submitting profile:', error);
      toast({
        title: "Error submitting profile",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePlay = (voiceName) => {
    const voice = voices.find(v => v.name === voiceName);
    if (voice && voice.sample_url) {
      if (playingVoice === voice.id) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setPlayingVoice(null);
      } else {
        if (playingVoice !== null) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        audioRef.current.src = voice.sample_url;
        audioRef.current.play();
        setPlayingVoice(voice.id);
      }
    } else {
      console.log('No sample URL available');
    }
  };

  useEffect(() => {
    const handleAudioEnded = () => {
      setPlayingVoice(null);
    };

    const currentAudio = audioRef.current;
    currentAudio.addEventListener('ended', handleAudioEnded);

    return () => {
      if (currentAudio) {
        currentAudio.removeEventListener('ended', handleAudioEnded);
      }
    };
  }, [audioRef]);

  const handleDialAgent = (profileId) => {
    // TODO: Implement the dialing functionality
    console.log(`Dialing agent with profile ID: ${profileId}`);
    toast({
      title: "Dialing Agent",
      description: `Initiating call to agent with profile ID: ${profileId}`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleStartTextSession = (profile) => {
    setCurrentChatProfile(profile);
    setIsChatOpen(true);
  };

  const handleCloseChatModal = () => {
    setIsChatOpen(false);
    setCurrentChatProfile(null);
  };


  const handleAddProfile = () => {
    setSelectedType(null);
    onTypeModalOpen();
  };

  const handleTypeSelection = (type) => {
    setSelectedType(type);
    setCurrentProfile(prev => ({...prev, type, subtype: type === 'Inbound' ? 'BlackBox' : 'Custom'}));
  };

  const handleSubtypeSelection = (subtype) => {
    setCurrentProfile(prev => ({...prev, subtype}));
    onTypeModalClose();
    setCurrentProfile(prev => ({
      ...prev,
      gender: 'Male',
    }));
    onOpen();
  };

  return (
    <Box p={8}>
      <Heading as="h1" size="xl" mb={3}>{pageTitle}</Heading>
      <Box mb={6} fontSize="md" color="gray.400">
        {t("Agent Profiles represent the personalities of AI agents, configuring their behavior during phone interactions. These profiles define how agents communicate and respond to callers, ensuring a consistent and tailored experience.")}
        <Box mt={2}>
          <Link href="/help#agent-profiles" color="blue.300" fontWeight="medium">
            {t("Learn more about Agent Profiles")}
          </Link>
        </Box>
      </Box>
      {/* Rest of the component content */}
      <Flex mb={4} alignItems="center">
        <IconButton
          aria-label="Add Profile"
          icon={<FaPlus />}
          onClick={handleAddProfile}
          colorScheme="blue"
          mr={2}
        />
      </Flex>
      {isLargerThan1280 && (
        <Flex alignItems="center" mb={4}>
          <Text mr={2}>{t("Table")}</Text>
          <Switch
            isChecked={viewMode === 'card'}
            onChange={() => setViewMode(viewMode === 'table' ? 'card' : 'table')}
            colorScheme="green"
          />
          <Text ml={2}>{t("Card")}</Text>
        </Flex>
      )}
      {viewMode === 'table' ? (
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              {['type', 'subtype', 'endpoint', 'name', 'gender', 'voice'].map((key) => (
                <Th key={key} onClick={() => handleSort(key)} cursor="pointer" _hover={{ bg: "gray.600" }}>
                  <Flex alignItems="center">
                    {key === 'icon' ? '' : key === 'endpoint' ? t('Endpoint') : t(key.charAt(0).toUpperCase() + key.slice(1))}
                    {sortKeys[0] === key && (
                      <Box ml={2}>
                        {sortOrders[0] === 'asc' ? <FaSortUp /> : <FaSortDown />}
                      </Box>
                    )}
                  </Flex>
                </Th>
              ))}
              <Th>{t("Actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortAndFilterProfiles(profilesWithExtensions, sortKeys, sortOrders).map((profile) => (
              <Tr key={profile.id} _hover={{ bg: "gray.600" }}>
                <Td>
                  <Badge 
                    bg={profile.type === 'Inbound' ? 'purple.700' : 'blue.500'}
                    color="white"
                  >
                    {profile.type}
                  </Badge>
                </Td>
                <Td>
                  <Badge 
                    bg="gray.500"
                    color="white"
                  >
                    {profile.subtype}
                  </Badge>
                </Td>
                <Td>{profile.extension}</Td>
                <Td fontWeight="bold">{profile.name}</Td>
                <Td>{profile.gender}</Td>
                <Td>{profile.voice}</Td>
                <Td>
                  <HStack spacing={2}>
                    <IconButton
                      icon={<FaPhone />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleDialAgent(profile.id)}
                      aria-label={t("Dial")}
                      colorScheme="green"
                    />
                    <IconButton
                      icon={<FaComments />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleStartTextSession(profile)}
                      aria-label={t("Text Session")}
                      colorScheme="blue"
                    />
                    <IconButton
                      icon={<FaEdit />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleEditProfile(profile)}
                      aria-label={t("Edit")}
                      colorScheme="orange"
                    />
                    <IconButton
                      icon={<FaTrash />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleDeleteProfile(profile.id)}
                      aria-label={t("Delete")}
                      colorScheme="red"
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <SimpleGrid columns={isLargerThan768 ? 3 : 1} spacing={4}>
          {sortAndFilterProfiles(profilesWithExtensions, sortKeys, sortOrders).map((profile) => (
            <motion.div
              key={profile.id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              animate={{ scale: selectedCard === profile.id ? 1.05 : 1 }}
              transition={{ duration: 0.2 }}
            >
              <Box
                borderRadius="lg"
                p={4}
                position="relative"
                display="flex"
                flexDirection="column"
                height="100%"
                onClick={() => setSelectedCard(selectedCard === profile.id ? null : profile.id)}
                cursor="pointer"
                bg={cardBgColor}
                color={cardTextColor}
                boxShadow="md"
                transition="all 0.2s"
                borderColor={selectedCard === profile.id ? "green.500" : "transparent"}
                borderWidth="2px"
                _hover={{
                  boxShadow: "lg",
                  transform: "translateY(-2px)",
                }}
              >
                <Badge 
                  bg={profile.type === 'Inbound' ? 'purple.700' : 'blue.500'}
                  color="white"
                  position="absolute" 
                  top={2} 
                  left={2}
                >
                  {profile.type}
                </Badge>
                <Badge 
                  bg="gray.500"
                  color="white"
                  position="absolute" 
                  top={2} 
                  right={2}
                >
                  {profile.subtype}
                </Badge>
                {profile.extension && profile.extension !== '' && (
                  <Badge
                    bg="green.500"
                    color="white"
                    position="absolute"
                    top={2}
                    left="50%"
                    transform="translateX(-50%)"
                  >
                    ACTIVE
                  </Badge>
                )}
                <VStack align="stretch" spacing={1} mt={6} flex={1}>
                  <Heading size="md">{profile.name}</Heading>
                  <Text fontSize="sm" color={cardDescriptionColor}>{profile.description}</Text>
                  <Text fontSize="sm" color={cardDetailColor}><strong>{t("Endpoint")}:</strong> {profile.extension}</Text>
                  <Text fontSize="sm" color={cardDetailColor}><strong>{t("Gender")}:</strong> {profile.gender}</Text>
                  <Text fontSize="sm" color={cardDetailColor}><strong>{t("Voice")}:</strong> {profile.voice}</Text>
                </VStack>
                <HStack spacing={2} mt={4} justifyContent="center" position="relative">
                  <IconButton
                    icon={<FaPhone />}
                    size="sm"
                    variant="ghost"
                    onClick={(e) => { e.stopPropagation(); handleDialAgent(profile.id); }}
                    aria-label={t("Dial")}
                    colorScheme="green"
                  />
                  <IconButton
                    icon={<FaComments />}
                    size="sm"
                    variant="ghost"
                    onClick={(e) => { e.stopPropagation(); handleStartTextSession(profile); }}
                    aria-label={t("Text Session")}
                    colorScheme="blue"
                  />
                  <IconButton
                    icon={<FaEdit />}
                    size="sm"
                    variant="ghost"
                    onClick={(e) => { e.stopPropagation(); handleEditProfile(profile); }}
                    aria-label={t("Edit")}
                    colorScheme="orange"
                  />
                  <IconButton
                    icon={<FaTrash />}
                    size="sm"
                    variant="ghost"
                    onClick={(e) => { e.stopPropagation(); handleDeleteProfile(profile.id); }}
                    aria-label={t("Delete")}
                    colorScheme="red"
                  />
                </HStack>
                {/* Cost boxes removed */}
              </Box>
            </motion.div>
          ))}
        </SimpleGrid>
      )}

      <Modal isOpen={isTypeModalOpen} onClose={onTypeModalClose}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue("white", "black")} borderWidth="1px" borderColor="gray.400">
          <ModalHeader>{t("Select Profile Type")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={2} spacing={4} mb={4}>
              <Box
                as="button"
                p={4}
                borderWidth={2}
                borderRadius="md"
                borderColor={selectedType === 'Inbound' ? 'green.500' : 'gray.600'}
                bg="gray.900"
                color="white"
                onClick={() => handleTypeSelection('Inbound')}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100px"
                transition="all 0.2s"
                _hover={{ transform: 'scale(1.05)' }}
              >
                <Icon as={FaPhoneAlt} fontSize="2xl" mb={2} color="blue.400" />
                <Text fontSize="sm">{t("Inbound")}</Text>
              </Box>
              <Box
                as="button"
                p={4}
                borderWidth={2}
                borderRadius="md"
                borderColor={selectedType === 'Outbound' ? 'green.500' : 'gray.600'}
                bg="gray.900"
                color="white"
                onClick={() => handleTypeSelection('Outbound')}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100px"
                transition="all 0.2s"
                _hover={{ transform: 'scale(1.05)' }}
              >
                <Icon as={FaPhoneVolume} fontSize="2xl" mb={2} color="green.400" />
                <Text fontSize="sm">{t("Outbound")}</Text>
              </Box>
            </SimpleGrid>
            {selectedType && (
              <Box mt={4}>
                <Text fontWeight="bold" mb={2}>{t("Subtype")}</Text>
                <SimpleGrid columns={3} spacing={4}>
                  {selectedType === 'Inbound' ? (
                    [
                      { name: 'Receptionist', icon: FaHeadset, color: 'blue.500' },
                      { name: 'Technical Support', icon: FaWrench, color: 'green.500' },
                      { name: 'Information Line', icon: FaInfoCircle, color: 'purple.500' },
                      { name: 'Restaurant Order', icon: FaUtensils, color: 'orange.500' },
                      { name: 'Taxi dispatcher', icon: FaTaxi, color: 'yellow.500' },
                      { name: 'Custom', icon: FaUser, color: 'gray.500' }
                    ].map((subtype) => (
                      <Box
                        key={subtype.name}
                        as="button"
                        p={4}
                        borderWidth={1}
                        borderRadius="md"
                        bg="gray.900"
                        color="white"
                        onClick={() => handleSubtypeSelection(subtype.name)}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="100px"
                        transition="all 0.2s"
                        _hover={{ transform: 'scale(1.05)' }}
                      >
                        <Icon as={subtype.icon} fontSize="2xl" mb={2} color={subtype.color} />
                        <Text fontSize="sm">{t(subtype.name)}</Text>
                      </Box>
                    ))
                  ) : (
                    [
                      { name: 'Cold Caller lead generator', icon: FaUserPlus, color: 'red.500' },
                      { name: 'Appointment Confirmation', icon: FaCalendarCheck, color: 'teal.500' },
                      { name: 'Custom', icon: FaUser, color: 'gray.500' }
                    ].map((subtype) => (
                      <Box
                        key={subtype.name}
                        as="button"
                        p={4}
                        borderWidth={1}
                        borderRadius="md"
                        bg="gray.900"
                        color="white"
                        onClick={() => setCurrentProfile(prev => ({...prev, subtype: subtype.name}))}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="100px"
                        transition="all 0.2s"
                        _hover={{ transform: 'scale(1.05)' }}
                      >
                        <Icon as={subtype.icon} fontSize="2xl" mb={2} color={subtype.color} />
                        <Text fontSize="sm">{t(subtype.name)}</Text>
                      </Box>
                    ))
                  )}
                </SimpleGrid>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            {/* Footer content removed */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size={isLargerThan1280 ? "4xl" : "xl"}>
        <ModalOverlay />
        <ModalContent maxW={isLargerThan1280 ? "50%" : "80%"} borderWidth="1px" borderColor="gray.300" borderRadius="md">
          <ModalHeader>
            {currentProfile ? t("Edit Profile") : t("Add Profile")}
          </ModalHeader>
          {currentProfile?.type && (
            <Box px={6} pb={2}>
              <Badge 
                bg={currentProfile.type === 'Inbound' ? 'purple.700' : 'blue.500'}
                color="white"
              >
                {currentProfile.type}
              </Badge>
              {currentProfile?.subtype && (
                <Badge 
                  ml={2}
                  bg="gray.500"
                  color="white"
                >
                  {currentProfile.subtype}
                </Badge>
              )}
            </Box>
          )}
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <Tabs>
                <TabList>
                  <Tab>
                    {t("Main")}
                    {(!currentProfile?.name || !currentProfile?.type || !currentProfile?.subtype || !currentProfile?.prompt) && (
                      <Badge ml={2} colorScheme="red" variant="solid" borderRadius="full">!</Badge>
                    )}
                  </Tab>
                  <Tab>
                    {t("Voice")}
                    {(!currentProfile?.gender || !currentProfile?.voice) && (
                      <Badge ml={2} colorScheme="red" variant="solid" borderRadius="full">!</Badge>
                    )}
                  </Tab>
                  <Tab>{t("VoIP")}</Tab>
                  <Tab>{t("Privacy")}</Tab>
                  <Tab>{t("Memories")}</Tab>
                  <Tab>{t("Misc")}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <FormControl isRequired mt={2}>
                      <FormLabel>{t("Name")}</FormLabel>
                      {currentProfile?.subtype === 'Custom' && (
                        <Text fontSize="sm" color="gray.500" mb={1}>
                          {t("Will not be used as agent name in custom profiles")}
                        </Text>
                      )}
                      <DebouncedInput
                        id="profile-name"
                        name="name"
                        value={currentProfile?.name || ''}
                        onChange={(value) => setCurrentProfile(prev => ({ ...prev, name: value }))}
                        required
                      />
                    </FormControl>
                    {currentProfile?.subtype === 'Receptionist' && (
                      <>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Company Name")}</FormLabel>
                          <DebouncedInput
                            id="company-name"
                            name="company_name"
                            value={currentProfile?.subtypeData?.company_name || ''}
                            onChange={(value) => setCurrentProfile(prev => ({
                              ...prev,
                              subtypeData: { ...prev.subtypeData, company_name: value }
                            }))}
                            required
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>{t("Industry")}</FormLabel>
                          <Select
                            name="industry"
                            value={currentProfile?.subtypeData?.industry || ''}
                            onChange={(e) => {
                              const selectedIndustry = e.target.value;
                              setCurrentProfile(prev => ({
                                ...prev,
                                subtypeData: {
                                  ...prev.subtypeData,
                                  industry: selectedIndustry,
                                  otherIndustry: selectedIndustry === 'Other' ? '' : prev.subtypeData?.otherIndustry || ''
                                }
                              }));
                            }}
                          >
                            <option value="">{t("Select an industry")}</option>
                            <option value="Insurance">{t("Insurance")}</option>
                            <option value="Finance">{t("Finance")}</option>
                            <option value="Medical">{t("Medical")}</option>
                            <option value="Technology">{t("Technology")}</option>
                            <option value="Education">{t("Education")}</option>
                            <option value="Retail">{t("Retail")}</option>
                            <option value="Hospitality">{t("Hospitality")}</option>
                            <option value="Manufacturing">{t("Manufacturing")}</option>
                            <option value="Other">{t("Other")}</option>
                          </Select>
                        </FormControl>
                        {currentProfile?.subtypeData?.industry === 'Other' && (
                          <FormControl mt={2}>
                            <FormLabel>{t("Specify Other Industry")}</FormLabel>
                            <DebouncedInput
                              id="other-industry"
                              name="otherIndustry"
                              value={currentProfile?.subtypeData?.otherIndustry || ''}
                              onChange={(value) => setCurrentProfile(prev => ({
                                ...prev,
                                subtypeData: {
                                  ...prev.subtypeData,
                                  otherIndustry: value
                                }
                              }))}
                              placeholder={t("Enter industry")}
                            />
                          </FormControl>
                        )}
                        <FormControl mt={4} display="flex" alignItems="center">
                          <FormLabel mb="0">{t("Customize greeting format")}</FormLabel>
                          <Switch 
                            name="use_specific_greeting" 
                            isChecked={currentProfile?.subtypeData?.use_specific_greeting || false} 
                            onChange={(e) => {
                              const useSpecificGreeting = e.target.checked;
                              setCurrentProfile(prev => ({
                                ...prev,
                                subtypeData: {
                                  ...prev.subtypeData,
                                  use_specific_greeting: useSpecificGreeting,
                                  greeting_message: useSpecificGreeting ? prev.subtypeData?.greeting_message || '' : null
                                }
                              }));
                            }}
                          />
                        </FormControl>
                        {currentProfile?.subtypeData?.use_specific_greeting && (
                          <FormControl mt={4}>
                            <FormLabel>{t("Greeting Message")}</FormLabel>
                            <Text fontSize="sm" color="gray.500" mb={2}>
                              {t("You can use {name} and {company_name} variables in your message. They will be replaced with the agent's name and company name during execution.")}
                            </Text>
                            <DebouncedInput
                              as={Textarea}
                              name="greeting_message" 
                              value={currentProfile?.subtypeData?.greeting_message || ''}
                              onChange={(value) => setCurrentProfile(prev => ({
                                ...prev,
                                subtypeData: {
                                  ...prev.subtypeData,
                                  greeting_message: value
                                }
                              }))}
                              placeholder={t("Hi, welcome to {company_name}, my name is {name}, how can I assist you today?")}
                            />
                          </FormControl>
                        )}
                      </>
                    )}
                    {currentProfile?.subtype === 'Technical Support' && (
                      <>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Product Name")}</FormLabel>
                          <Input id="product-name" name="product_name" defaultValue={currentProfile?.subtypeData?.product_name} required />
                        </FormControl>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Support Level")}</FormLabel>
                          <Select name="support_level" defaultValue={currentProfile?.subtypeData?.support_level} required>
                            <option value="Basic">{t("Basic")}</option>
                            <option value="Advanced">{t("Advanced")}</option>
                            <option value="Expert">{t("Expert")}</option>
                          </Select>
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>{t("Knowledge Base URL")}</FormLabel>
                          <Input id="knowledge-base-url" name="knowledge_base_url" defaultValue={currentProfile?.subtypeData?.knowledge_base_url} />
                        </FormControl>
                      </>
                    )}
                    {currentProfile?.subtype === 'Information Line' && (
                      <>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Topic")}</FormLabel>
                          <Input id="topic" name="topic" defaultValue={currentProfile?.subtypeData?.topic} required />
                        </FormControl>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Information Source")}</FormLabel>
                          <Input id="information-source" name="information_source" defaultValue={currentProfile?.subtypeData?.information_source} required />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>{t("Update Frequency")}</FormLabel>
                          <Input id="update-frequency" name="update_frequency" defaultValue={currentProfile?.subtypeData?.update_frequency} />
                        </FormControl>
                      </>
                    )}
                    {currentProfile?.subtype === 'Restaurant Order' && (
                      <>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Restaurant Name")}</FormLabel>
                          <Input id="restaurant-name" name="restaurant_name" defaultValue={currentProfile?.subtypeData?.restaurant_name} required />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>{t("Cuisine Type")}</FormLabel>
                          <Input id="cuisine-type" name="cuisine_type" defaultValue={currentProfile?.subtypeData?.cuisine_type} />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>{t("Menu URL")}</FormLabel>
                          <Input id="menu-url" name="menu_url" defaultValue={currentProfile?.subtypeData?.menu_url} />
                        </FormControl>
                      </>
                    )}
                    {currentProfile?.subtype === 'Taxi dispatcher' && (
                      <>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Company Name")}</FormLabel>
                          <Input id="taxi-company-name" name="company_name" defaultValue={currentProfile?.subtypeData?.company_name} required />
                        </FormControl>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Service Area")}</FormLabel>
                          <Textarea name="service_area" defaultValue={currentProfile?.subtypeData?.service_area} required />
                        </FormControl>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Pricing Model")}</FormLabel>
                          <Textarea name="pricing_model" defaultValue={currentProfile?.subtypeData?.pricing_model} required />
                        </FormControl>
                      </>
                    )}
                    {currentProfile?.subtype === 'Cold Caller lead generator' && (
                      <>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Campaign Name")}</FormLabel>
                          <Input id="campaign-name" name="campaign_name" defaultValue={currentProfile?.subtypeData?.campaign_name} required />
                        </FormControl>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Target Audience")}</FormLabel>
                          <Textarea name="target_audience" defaultValue={currentProfile?.subtypeData?.target_audience} required />
                        </FormControl>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Script")}</FormLabel>
                          <Textarea name="script" defaultValue={currentProfile?.subtypeData?.script} required />
                        </FormControl>
                      </>
                    )}
                    {currentProfile?.subtype === 'Appointment Confirmation' && (
                      <>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Business Type")}</FormLabel>
                          <Input id="business-type" name="business_type" defaultValue={currentProfile?.subtypeData?.business_type} required />
                        </FormControl>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Confirmation Method")}</FormLabel>
                          <Select name="confirmation_method" defaultValue={currentProfile?.subtypeData?.confirmation_method} required>
                            <option value="Phone">{t("Phone")}</option>
                            <option value="SMS">{t("SMS")}</option>
                            <option value="Email">{t("Email")}</option>
                          </Select>
                        </FormControl>
                        <FormControl mt={4} isRequired>
                          <FormLabel>{t("Reminder Schedule")}</FormLabel>
                          <Textarea name="reminder_schedule" defaultValue={currentProfile?.subtypeData?.reminder_schedule} required />
                        </FormControl>
                      </>
                    )}
                    {currentProfile?.subtype === 'Custom' && (
                      <>
                        <FormControl mt={4}>
                          <FormLabel>{t("Custom Agent Input")}</FormLabel>
                          <Textarea
                            name="custom_field1"
                            defaultValue={currentProfile?.subtypeData?.custom_field1}
                            rows={20}
                            minHeight="400px"
                            placeholder={t("Enter detailed instructions for the custom agent here...")}
                          />
                        </FormControl>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <FormControl isRequired>
                      <FormLabel>{t("Gender")}</FormLabel>
                      <Select
                        name="gender"
                        value={currentProfile?.gender || ''}
                        onChange={(e) => setCurrentProfile({...currentProfile, gender: e.target.value, voice: ''})}
                        required
                      >
                        <option value="">{t("Select a gender")}</option>
                        <option value="Male">{t("Male")}</option>
                        <option value="Female">{t("Female")}</option>
                      </Select>
                    </FormControl>
                    {currentProfile?.gender && (
                      <FormControl mt={4} isRequired>
                        <FormLabel>{t("Voice")}</FormLabel>
                        <Flex>
                          <Select 
                            name="voice" 
                            value={currentProfile?.voice || ''}
                            onChange={(e) => setCurrentProfile({...currentProfile, voice: e.target.value})}
                            flex="1"
                            mr={2}
                          >
                            <option value="" disabled>{t("Select a voice")}</option>
                            {voices
                              .filter(voice => voice.gender.toLowerCase() === currentProfile.gender.toLowerCase())
                              .map((voice) => (
                                <option key={voice.id} value={voice.name}>({voice.provider_name}) {voice.name}</option>
                              ))
                            }
                          </Select>
                          <VoicePlayButton
                            isPlaying={playingVoice === voices.find(v => v.name === currentProfile?.voice)?.id}
                            onClick={() => handlePlay(currentProfile?.voice)}
                            isDisabled={!currentProfile?.voice || !voices.find(v => v.name === currentProfile?.voice)?.sample_url}
                          />
                        </Flex>
                      </FormControl>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <FormControl>
                      <FormLabel>{t("Endpoint")}</FormLabel>
                      <Select
                        name="endpoint"
                        value={selectedEndpoint}
                        onChange={(e) => setSelectedEndpoint(e.target.value)}
                        defaultValue=""
                      >
                        <option value="">{t("No endpoint")}</option>
                        {(currentProfile?.id ? endpoints : unusedEndpoints)
                          .filter(endpoint => !endpoint.destination || endpoint.destination === currentProfile?.uuid)
                          .map((endpoint) => (
                            <option key={endpoint.id} value={endpoint.id}>
                              {endpoint.name} / {endpoint.sip_username}
                            </option>
                          ))
                        }
                      </Select>
                    </FormControl>
                    <FormControl mt={4} display="flex" alignItems="center">
                      <FormLabel htmlFor="enable_hangups" mb="0">
                        {t("Enable Hangups")}
                      </FormLabel>
                      <Switch
                        id="enable_hangups"
                        name="enable_hangups"
                        isChecked={currentProfile?.enable_hangups}
                        onChange={(e) => {
                          const enableHangups = e.target.checked;
                          setCurrentProfile(prev => ({
                            ...prev,
                            enable_hangups: enableHangups
                          }));
                        }}
                      />
                    </FormControl>
                    <FormControl mt={4} display="flex" alignItems="center">
                      <FormLabel htmlFor="enable_transfers" mb="0">
                        {t("Enable Transfers")}
                      </FormLabel>
                      <Switch
                        id="enable_transfers"
                        name="enable_transfers"
                        isChecked={currentProfile?.enable_transfers}
                        onChange={(e) => {
                          const enableTransfers = e.target.checked;
                          setCurrentProfile(prev => ({
                            ...prev,
                            enable_transfers: enableTransfers,
                            company_directory_source: enableTransfers ? 'manual' : undefined,
                            transfer_directory_manual: enableTransfers ? (prev.transfer_directory_manual || '') : undefined,
                          }));
                        }}
                      />
                    </FormControl>
                    {currentProfile?.enable_transfers && (
                      <FormControl mt={4}>
                        <FormLabel>{t("Company Directory")}</FormLabel>
                        <DebouncedTextarea
                          placeholder={t("Support/Technical Support department: 1001\nSales department: 1002\nMike Duff, CEO: 1003")}
                          value={currentProfile?.transfer_directory_manual || ''}
                          onChange={(value) => setCurrentProfile({...currentProfile, transfer_directory_manual: value})}
                          rows={5}
                        />
                      </FormControl>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <FormControl mb={4}>
                      <FormLabel>{t("Data Retention")}</FormLabel>
                      <Select name="data_retention" defaultValue={currentProfile?.data_retention || "30_days"}>
                        <option value="30_days">{t("30 days")}</option>
                        <option value="60_days">{t("60 days")}</option>
                        <option value="180_days">{t("180 days")}</option>
                        <option value="365_days">{t("365 days")}</option>
                      </Select>
                    </FormControl>
                    <FormControl display="flex" alignItems="center" mb={4}>
                      <FormLabel htmlFor="call_recording" mb="0">
                        {t("Call Recording")}
                      </FormLabel>
                      <Switch
                        id="call_recording"
                        name="call_recording"
                        isChecked={currentProfile?.call_recording !== false}
                        onChange={(e) => setCurrentProfile({...currentProfile, call_recording: e.target.checked})}
                      />
                    </FormControl>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="call_transcription" mb="0">
                        {t("Call Transcription")}
                      </FormLabel>
                      <Switch
                        id="call_transcription"
                        name="call_transcription"
                        isChecked={currentProfile?.call_transcription !== false}
                        onChange={(e) => setCurrentProfile({...currentProfile, call_transcription: e.target.checked})}
                      />
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <Box>
                      <FormControl display="flex" alignItems="center" mb={4}>
                        <FormLabel htmlFor="employee-handbook-switch" mb="0">
                          {t("Enable Employee Handbook")}
                        </FormLabel>
                        <Switch
                          id="employee-handbook-switch"
                          isChecked={currentProfile?.employeeHandbookEnabled || false}
                          onChange={(e) => setCurrentProfile({...currentProfile, employeeHandbookEnabled: e.target.checked})}
                        />
                      </FormControl>
                      {currentProfile?.employeeHandbookEnabled && (
                        <Box>
                          <FormControl mb={4}>
                            <FormLabel>{t("Upload Employee Handbook (PDF)")}</FormLabel>
                            <Input
                              id="employee-handbook-upload"
                              type="file"
                              accept=".pdf"
                              onChange={(e) => {
                                // Handle file upload logic here
                                console.log("File selected:", e.target.files[0]);
                              }}
                            />
                          </FormControl>
                          <Link href="/help#employee-handbook" color="blue.500">
                            {t("Learn more about Employee Handbook feature")}
                          </Link>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Flex>
                      <FormControl flex="1" mr={2}>
                        <FormLabel>{t("Type")}</FormLabel>
                        <Input name="type" value={currentProfile?.type || ''} isReadOnly />
                      </FormControl>
                      <FormControl flex="1">
                        <FormLabel>{t("Subtype")}</FormLabel>
                        <Input 
                          name="subtype" 
                          value={currentProfile?.subtype || ""}
                          isReadOnly
                        />
                      </FormControl>
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="gray"
                mr="auto"
                onClick={() => {
                  onClose();
                  onTypeModalOpen();
                }}
                leftIcon={<FaArrowLeft />}
              >
                {t("Back")}
              </Button>
              <Button 
                colorScheme="purple" 
                mr={3} 
                type="submit"
                leftIcon={<FaSave />}
              >
                {currentProfile ? t("Update") : t("Add")}
              </Button>
              <Button onClick={onClose}>{t("Cancel")}</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {isChatOpen && currentChatProfile && (
        <ChatModal
          isOpen={isChatOpen}
          onClose={handleCloseChatModal}
          profile={currentChatProfile}
        />
      )}
    </Box>
  );
});

export default AgentProfiles;
