import React from 'react';
import { Box, Flex, Heading, Text, Button, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ChangePlan = () => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const plans = [
    { name: 'Pay as you go', price: 0, features: ['No monthly fee', 'Pay only for what you use', 'Basic support'] },
    { name: 'Basic', price: 9.99, features: ['100 minutes', 'Basic support', '24/7 support'] },
    { name: 'Enterprise', price: 49.99, features: ['Unlimited minutes', 'Dedicated account manager', 'Custom integrations', 'Advanced analytics'] },
  ];

  return (
    <Box p={8}>
      <Heading mb={6}>{t("Change Your Plan")}</Heading>
      <Flex justifyContent="space-between" flexWrap="wrap">
        {plans.map((plan, index) => (
          <Box key={index} p={6} borderWidth={1} borderRadius="lg" bg={bgColor} borderColor={borderColor} width={["100%", "48%", "23%"]} mb={4}>
            <Heading size="md" mb={4}>{plan.name}</Heading>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>${plan.price}/month</Text>
            <Box mb={4}>
              {plan.features.map((feature, featureIndex) => (
                <Text key={featureIndex}>• {feature}</Text>
              ))}
            </Box>
            <Button colorScheme="blue" width="100%">{t("Select Plan")}</Button>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default ChangePlan;
